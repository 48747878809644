import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  errorMessage: null,
  message: null,
  error: false,
  success: false,
  groupAjo: {
    id: null,
    name: null,
    duration: null,
    finishDate: null,
    amount: null,
    year: null,
    status: null,
    type: null,
    description: null,
    memberCapacity: null,
    invitationLink: null,
    startMonth: null,
    members: [],
    startMonthId: null,
  },
  ajoInfo: {
    id: null,
    name: null,
    duration: null,
    finishDate: null,
    amount: null,
    description: null,
    memberCapacity: null,
    members: [],
    startMonthId: null,
    dateCreated: null,
  },
  individualAjo: {
    id: null,
    name: null,
    duration: null,
    finishDate: null,
    amount: null,
    year: null,
    status: null,
    type: null,
    description: null,
    memberCapacity: null,
    invitationLink: null,
    groupLink: null,
    startMonth: null,
    members: [],
    paymentDto: {
      amountWithCharge: null,
      transactionRef: null,
      isPaid: false,
    },
    startMonthId: null,
    currentAjoCyle: null,
  },
  ajoDataByIdInfo: {
    name: null,
    dateCreated: null,
    description: null,
    members: [],
  },
  availableAjoGroups: [],
  userAjoGroups: [],
  userAjoPaymentHistory: [],
  memberTotalContribution: 0,
  ajoGroupCreated: false,
  ajoPaymentRequested: false,
  paymentRequests: [],
  requestAjoPaymentModalIsOpen: false,
};

const createExistingGroupAjoSuccess = (state, action) => {
  return updateObject(state, { groupAjo: action.data, ajoGroupCreated: true });
};

const createIndividualAjoSuccess = (state, action) => {
  return updateObject(state, { individualAjo: action.data, ajoGroupCreated: true });
};

const ajoOperationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    errorMessage: null,
    message: null,
    error: false,
    success: false,
  });
};

const ajoOperationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorMessage: action.error,
    error: true,
    success: false,
  });
};

const ajoOperationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorMessage: null,
    message: action.message,
    error: false,
  });
};

function getAjoLiquidationRequestsSuccess(state, action) {
  return updateObject(state, { paymentRequests: action.data });
}

function requestAjoPaymentSuccess(state) {
  return updateObject(state, { ajoPaymentRequested: true });
}

const getAvailableAjoGroupsSuccess = (state, action) => {
  return updateObject(state, { availableAjoGroups: action.data });
};

const getUserAjoGroupsSuccess = (state, action) => {
  return updateObject(state, { userAjoGroups: action.data });
};

const resetAjoState = (state, action) => {
  return updateObject(state, { ...action.data });
};

const getAjoGroupInfo = (state, action) => {
  return updateObject(state, { individualAjo: action.data });
};

const getAjoGroupData = (state, action) => {
  return updateObject(state, { ajoInfo: action.data });
};

const getAjoPaymentTransactionSuccess = (state, action) => {
  return updateObject(state, { userAjoPaymentHistory: action.data });
};

const getMemberTotalContribution = (state, action) => {
  return updateObject(state, {
    memberTotalContribution: action.data.totalContributions,
  });
};

function getAjoDataById(state, action) {
  return updateObject(state, {
    ajoDataByIdInfo: action.data,
  });
}

function toggleRequestAjoPaymentModal(state, action) {
  return updateObject(state, { requestAjoPaymentModalIsOpen: action.data.status });
}

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.CREATE_EXISTING_GROUP_AJO_SUCCESS:
      return createExistingGroupAjoSuccess(state, action);
    case actionTypes.CREATE_INDIVIDUAL_AJO_SUCCESS:
      return createIndividualAjoSuccess(state, action);
    case actionTypes.AJO_OPERATION_START:
      return ajoOperationStart(state, action);
    case actionTypes.AJO_OPERATION_FAIL:
      return ajoOperationFail(state, action);
    case actionTypes.AJO_OPERATION_SUCCESS:
      return ajoOperationSuccess(state, action);
    case actionTypes.GET_AVAILABLE_AJO_GROUPS_SUCCESS:
      return getAvailableAjoGroupsSuccess(state, action);
    case actionTypes.GET_USER_AJO_GROUPS_SUCCESS:
      return getUserAjoGroupsSuccess(state, action);
    case actionTypes.RESET_AJO_STATE:
      return resetAjoState(state, action);
    case actionTypes.GET_AJO_GROUP_INFO:
      return getAjoGroupInfo(state, action);
    case actionTypes.GET_AJO_GROUP_DATA:
      return getAjoGroupData(state, action);
    case actionTypes.GET_AJO_PAYMENT_TRANSACTIONS:
      return getAjoPaymentTransactionSuccess(state, action);
    case actionTypes.AJO_MEMBER_CONTRIBUTION:
      return getMemberTotalContribution(state, action);
    case actionTypes.GET_AJO_DATA_BY_ID_SUCCESS:
      return getAjoDataById(state, action);
    case actionTypes.REQUEST_AJO_PAYMENT:
      return requestAjoPaymentSuccess(state);
    case actionTypes.GET_AJO_LIQUIDATION_REQUESTS:
      return getAjoLiquidationRequestsSuccess(state, action);
    case actionTypes.TOGGLE_REQUEST_AJO_FUND_MODAL:
      return toggleRequestAjoPaymentModal(state, action);
    default:
      return state;
  }
};

export default reducer;
