import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "antd";

import * as actions from "../../store/actions";
import SearchedSavingsGroup from "../Home/recent_group_savings";
import Button from "../../Components/common/Button";
import CenterItem from "../../Components/common/centerItem";

const SavingsSearch = () => {
  const { userGroupSavings } = useSelector((state) => state["savings"]);
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  const closeTopDrawer = () => {
    setName("");
    dispatch(actions.closeTopDrawer());
  };

  const searchSavingsHandler = () => {
    if (userGroupSavings.length <= 0) {
      return <CenterItem style={styles.centerText}>No group savings available</CenterItem>;
    }

    if (name.length <= 0) {
      return <CenterItem style={styles.centerText}>Start typing to search...</CenterItem>;
    }

    const searchedArray = userGroupSavings.filter((savings) => {
      const savingsName = savings.name.toLowerCase();
      return savingsName.includes(name.toLowerCase());
    });

    if (name.length > 0 && searchedArray.length <= 0) {
      return <CenterItem style={styles.centerText}>No result found.</CenterItem>;
    }

    const savingsGroup = [...searchedArray];
    const recentSavingsGroups = savingsGroup.reverse();

    return recentSavingsGroups.map((savings) => (
      <SearchedSavingsGroup
        id={savings.id}
        name={savings.name}
        memberCapacity={savings.members.length}
        key={savings.id}
      />
    ));
  };

  return (
    <div style={styles.search}>
      <Button style={{ background: "red", float: "right" }} onClick={closeTopDrawer}>
        Cancel
      </Button>
      <Input
        placeholder="Search for active savings groups"
        onChange={(event) => setName(event.target.value)}
        style={{ marginTop: "1rem" }}
      />
      <div className="mt-3">{searchSavingsHandler()}</div>
    </div>
  );
};

const styles = {
  search: { marginTop: "2rem" },
  centerText: { color: "gray" },
};

export default SavingsSearch;
