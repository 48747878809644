import React from "react";
import Colors from "../../Constants/color";

const button = (props) => {
  return (
    <div
      style={{ ...styles.button, ...props.style }}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const styles = {
  button: {
    alignItems: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
    padding: "8px 14px",
    background: Colors.primary,
    borderRadius: "5px",
    cursor: "pointer",
  },
};
export default button;
