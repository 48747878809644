import React from "react";

const CenterItem = (props) => {
  return (
    <div style={{ ...styles.center, ...props.style }}>{props.children}</div>
  );
};

const styles = {
  center: {
    display: "flex",
    marginTop: "2rem",
    alignItems: "center",
    justifyContent: "center",
  },
};
export default CenterItem;
