import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer } from "antd";
import * as actions from "../../store/actions";

const AjoDrawer = (props) => {
  const ajoDrawerIsOpen = useSelector((state) => state["utility"].ajoDrawerIsOpen);
  const dispatch = useDispatch();

  const onCloseAjoDrawer = () => {
    dispatch(actions.closeAjoDrawer());
  };

  return (
    <Drawer
      onClose={onCloseAjoDrawer}
      visible={ajoDrawerIsOpen}
      placement="left"
      closable={false}
      width={520}
    >
      <div className="ajo-create-drawer">{props.children}</div>
    </Drawer>
  );
};

export default AjoDrawer;
