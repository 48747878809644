import * as actionTypes from "./actionTypes";
import apiCall from "axios";

import { errorMessage, successMessage, infoMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const createGroupSavings = (formData) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      formData = { ...formData, userEmail: getState().account.email };
      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/CreateGroupSavings`, formData);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to create group savings at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        res.data.message = "Operation Successful!";
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(createGroupSavingsSuccess(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const getMemberTotalSavings = (id) => {
  return async (dispatch, getState) => {
    try {
      let email = getState().account.email;

      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/member-savings/${id}?email=${email}`
      );

      if (res && res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getMemberTotalSavingsSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail(error.response.data.message));
      }
    }
  };
};

export const getGroupSavingsInactiveMembers = (id) => {
  return async (dispatch, getState) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupSavings/inactive/${id}`);

      if (res && res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getInactiveMembersSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail());
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail(error.response.data.message));
      }
    }
  };
};

export const getGroupSavingsInfoById = (id) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupSavings/GetGroupSavingsInfo/${id}`);

      if (res && res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getGroupSavingsInfo(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail());
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const getUserGroupSavings = () => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/GetUserGroupSavings/${getState().account.email}`
      );

      if (res && res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getUserGroupSavingsSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail());
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const inviteSavingsMember = (savingsInviteData) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      savingsInviteData = {
        ...savingsInviteData,
        userEmail: getState().account.email,
      };

      let res = await apiCall.post(
        `${baseUrl}/api/GroupSavings/SendSavingsInvitationLink`,
        savingsInviteData
      );

      if (res && res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(groupSavingsFail());
      errorMessage(error);
    }
  };
};

export const joinGroupSavings = (savingsJoinData) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsFail());

    try {
      savingsJoinData = {
        ...savingsJoinData,
        email: savingsJoinData.email ? savingsJoinData.email : getState().account.email,
      };

      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/JoinGroupSavings`, savingsJoinData);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to join at the moment, please try again later");
        return;
      }

      if (res && res.data.isSuccessful) {
        res.data.message = "Congratulations, you have successfully joined the group.";
        dispatch(groupSavingsSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(groupSavingsFail());
      errorMessage(error);
    }
  };
};

export const setGroupValidator = (data) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.patch(`${baseUrl}/api/GroupSavings/setGroupValidator`, data);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to set group validator at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess());
        dispatch(setGroupValidatorSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const approveGroupMember = (data) => {
  return async (dispatch) => {
    try {
      let res = await apiCall.patch(`${baseUrl}/api/GroupSavings/ApproveGroupMember`, data);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to approve group memeber at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(approveGroupMemberSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const deactivateGroupMember = (deactivateData) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.patch(
        `${baseUrl}/api/GroupSavings/DeactivateGroupMember`,
        deactivateData
      );

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to deactivate group member at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess());
        dispatch(deactivateGroupMemberSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const requestFund = (requestFundData) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      requestFundData["userId"] = getState().account.id;
      let res = await apiCall.post(
        `${baseUrl}/api/GroupSavings/group-savings-liquidation`,
        requestFundData
      );

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to request fund at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(requestFundSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const addGroupSavingsMember = (memberData) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/AddGroupMember`, memberData);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to add a group member at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        res.data.message = "Member added successfully";
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(addGroupSavingsMemberSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const createSubAccount = (formData) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      formData = { ...formData, userId: getState().account.id };

      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/CreateSubAccount`, formData);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to create a sub account at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(createSubAccountSuccess(res.data.returnedObject));
        successMessage(res.data.message);
        dispatch(getSavingsSubAccounts(formData.groupSavingsId));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const getUnsettledPayments = (savingsId) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());
    try {
      const userId = getState().account.id;

      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/user-unsettled-payments/${savingsId}/${userId}`
      );

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getUnsettledPaymentsSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const getSavingsSubAccounts = (savingsId) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupSavings/GetGroupSubAccounts/${savingsId}`);

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getSavingsSubAccountSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const getSubAccountInfo = (subaccountId, savingsId) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/sub-account/${savingsId}/${subaccountId}`
      );

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getUserSubAccountInfoSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const adminGetSavingsPaymentHistories = (subAccountId) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/savings-payment-history-admin/${subAccountId}`
      );

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getSavingsHistorySuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const getUserPaymentHistory = (payload) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/savings-payment-history`, payload);

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getUserPaymentHistorySuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const getLiquidationRequests = (savingsId) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/get-savings-liquidation-request-list/${savingsId}`
      );

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getLiquidationRequestSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const validateLiquidationRequest = (savingsId, requestId) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      const userId = getState().account.id;

      let res = await apiCall.get(
        `${baseUrl}/api/GroupSavings/validate-liquidation-request/${savingsId}/${userId}/${requestId}`
      );

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to validate request at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        successMessage(res.data.message);
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(validateLiquidationRequestSuccess());
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(groupSavingsFail());
        console.log(error.response.data.message);
      }
    }
  };
};

export const saveGroupDocument = (data) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      data = { ...data, userId: getState().account.id };

      let res = await apiCall.post(`${baseUrl}/api/GroupSavings/save-group-document`, data);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to save document at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getGroupDocuments(data.groupId));
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const getGroupDocuments = (savingsId) => {
  return async (dispatch) => {
    dispatch(groupSavingsStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupSavings/get-group-documents/${savingsId}`);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to retrieve documents at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        dispatch(getGroupDocumentSuccess(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const deleteGroupDocuments = (documentId) => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    try {
      const userId = getState().account.id;

      let res = await apiCall.delete(
        `${baseUrl}/api/GroupSavings/delete-group-document?documentId=${documentId}&userId=${userId}`
      );

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage("Unable to delete group document at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(groupSavingsFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      errorMessage(error);
      dispatch(groupSavingsFail());
    }
  };
};

export const getPendingGroupSavingsRequests = () => {
  return async (dispatch, getState) => {
    dispatch(groupSavingsStart());

    const email = getState().account.email;

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupSavings/pending-group-savings/${email}`);

      if (!res) {
        dispatch(groupSavingsFail());
        infoMessage(
          "Unable to retrieve group savings pending approval at the moment, please try again later"
        );
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(groupSavingsSuccess());
        dispatch(getPendingGroupSavings(res.data.returnedObject));
      } else {
        dispatch(groupSavingsFail());
      }
    } catch (error) {
      dispatch(groupSavingsFail());
    }
  };
};

function deactivateGroupMemberSuccess() {
  return { type: actionTypes.DEACTIVATE_GROUP_MEMBER_SUCCESS };
}

function setGroupValidatorSuccess() {
  return { type: actionTypes.SET_GROUP_VALIDATOR_SUCCESS };
}

const getGroupDocumentSuccess = (data) => {
  return { type: actionTypes.GET_GROUP_DOCUMENTS, data };
};

const getSavingsHistorySuccess = (data) => {
  return { type: actionTypes.ADMIN_GET_USER_PAYMENT_HISTORY, data };
};

function getUserPaymentHistorySuccess(data) {
  return { type: actionTypes.GET_USER_PAYMENT_HISTORY, data };
}

const getLiquidationRequestSuccess = (data) => {
  return { type: actionTypes.GET_SAVINGS_LIQUIDATION_SUCCESS, data };
};

const getInactiveMembersSuccess = (data) => {
  return { type: actionTypes.GET_INACTIVE_GROUP_SAVINGS_MEMBERS, data };
};

const getPendingGroupSavings = (data) => {
  return { type: actionTypes.GET_PENDING_GROUP_SAVINGS, data };
};

const createGroupSavingsSuccess = (data) => {
  return { type: actionTypes.CREATE_GROUP_SAVINGS_SUCCESS, data };
};

export const createSubAccountSuccess = (data) => {
  return { type: actionTypes.CREATE_SUB_ACCOUNT_SUCCESS, data };
};

const getSavingsSubAccountSuccess = (data) => {
  return { type: actionTypes.GET_SAVINGS_SUB_ACCOUNTS, data };
};

const groupSavingsStart = () => {
  return { type: actionTypes.SAVINGS_OPERATION_START };
};

const groupSavingsSuccess = (message) => {
  return { type: actionTypes.SAVINGS_OPERATION_SUCCESS, message };
};

const approveGroupMemberSuccess = () => {
  return { type: actionTypes.APPROVE_GROUP_MEMBER };
};

const groupSavingsFail = (error) => {
  return { type: actionTypes.SAVINGS_OPERATION_FAIL, error };
};

const getUserGroupSavingsSuccess = (data) => {
  return { type: actionTypes.GET_USER_GROUP_SAVINGS_SUCCESS, data };
};

const getGroupSavingsInfo = (data) => {
  return { type: actionTypes.GET_GROUP_SAVINGS_INFO, data };
};

const getMemberTotalSavingsSuccess = (data) => {
  return { type: actionTypes.GET_MEMBER_TOTAL_SAVINGS, data };
};

const getUnsettledPaymentsSuccess = (data) => {
  return { type: actionTypes.USER_UNSETTLED_PAYMENTS, data };
};

const getUserSubAccountInfoSuccess = (data) => {
  return { type: actionTypes.USER_SUBACCOUNT_INFO, data };
};

function validateLiquidationRequestSuccess() {
  return { type: actionTypes.VALIDATE_LIQUIDATION_REQUEST_SUCCESS };
}

function addGroupSavingsMemberSuccess() {
  return { type: actionTypes.ADD_GROUP_SAVINGS_MEMBER_SUCCESS };
}

function requestFundSuccess() {
  return { type: actionTypes.REQUEST_SAVINGS_FUND_SUCCESS };
}

export const toggleAddSavingsMemberModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_ADD_SAVINGS_MEMBER_MODAL, data });
  };
};

export const toggleRequestFundSavingsModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_REQUEST_FUND_SAVINGS_MODAL, data });
  };
};

export const toggleCreateSubAccountModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_CREATE_SUB_ACCOUNT_MODAL, data });
  };
};

export const resetSavingsState = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_SAVINGS_STATE, data });
  };
};
