import * as actionTypes from "./actionTypes";
import apiCall from "axios";

import { errorMessage, infoMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const notificationOperationStart = () => {
  return { type: actionTypes.NOTIFICATION_OPERATION_START };
};

export const notificationOperationSuccess = (message) => {
  return { type: actionTypes.NOTIFICATION_OPERATION_SUCCESS, message };
};

export const notificationOperationFail = (error) => {
  return { type: actionTypes.NOTIFICATION_OPERATION_FAIL, error };
};

export const getAllNotificationsSuccess = (data) => {
  return { type: actionTypes.GET_ALL_NOTIFICATION_SUCCESS, data };
};

export const getAllNotifications = () => {
  return async (dispatch, getState) => {
    dispatch(notificationOperationStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/Notification/all?email=${getState().account.email}`
      );

      if (res.data.isSuccessful) {
        dispatch(notificationOperationSuccess(res.data.message));
        dispatch(getAllNotificationsSuccess(res.data.returnedObject));
      } else {
        dispatch(notificationOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(notificationOperationFail(error.response.data.message));
      }
    }
  };
};

export const readAllNotificationsSuccess = () => {
  return { type: actionTypes.VIEW_ALL_NOTIFICATION };
};

export const readANotification = (id) => {
  return async (dispatch, getState) => {
    try {
      let res = await apiCall.patch(`${baseUrl}/api/Notification/${id}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(notificationOperationSuccess(res.data.message));
      } else {
        dispatch(notificationOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(notificationOperationFail(error.response.data.message));
      }
    }
  };
};

export const readAllNotifications = () => {
  return async (dispatch, getState) => {
    try {
      let res = await apiCall.patch(
        `${baseUrl}/api/Notification/all?email=${getState().account.email}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(notificationOperationSuccess(res.data.message));
      } else {
        dispatch(notificationOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(notificationOperationFail(error.response.data.message));
      }
    }
  };
};

export const getNotificationInfoSuccess = (data) => {
  return { type: actionTypes.GET_NOTIFICATION_INFO, data };
};

export const clearAllNotificationsSuccess = (message) => {
  return { type: actionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS, message };
};

export const getNotificationInfo = (id) => {
  return async (dispatch, getState) => {
    dispatch(notificationOperationStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/Notification/${id}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(notificationOperationSuccess(res.data.message));
        dispatch(getNotificationInfoSuccess(res.data.returnedObject));
      } else {
        dispatch(notificationOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(notificationOperationFail(error.response.data.message));
      }
    }
  };
};

export const deleteNotificationStart = () => {
  return { type: actionTypes.DELETE_NOTIFICATION_START };
};

export const deleteNotification = (id) => {
  return async (dispatch, getState) => {
    dispatch(notificationOperationStart());

    try {
      let res = await apiCall.delete(`${baseUrl}/api/Notification/${id}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(notificationOperationSuccess(res.data.message));
      } else {
        dispatch(notificationOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(notificationOperationFail());
      errorMessage(error);
    }
  };
};

export const clearAllNotifications = () => {
  return async (dispatch, getState) => {
    dispatch(deleteNotificationStart());

    try {
      let res = await apiCall.delete(
        `${baseUrl}/api/Notification/all?email=${getState().account.email}`
      );

      if (res.data.isSuccessful) {
        dispatch(clearAllNotificationsSuccess(res.data.message));
      } else {
        dispatch(notificationOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(notificationOperationFail());
      errorMessage(error);
    }
  };
};
