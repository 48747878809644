import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  message: null,
  paymentVerified: false,
  ajoPaymentMade: false,
  charge: {
    extras: [],
    total: null,
  },
  banks: [],
  success: false,
  error: false,
  transferAmount: 0,
  transferModalIsOpen: false,
  generateReferencePayload: {},
  transferPaymentResponse: {
    transactionRef: null,
    amount: null,
  },
  paymentMade: false,
};

const paymentOperationStart = (state) => {
  return updateObject(state, {
    loading: true,
    message: null,
    success: false,
    error: false,
  });
};

const paymentOperationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.error,
    success: false,
    error: true,
  });
};

const paymentOperationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.message,
    error: false,
  });
};

const getPaymentChargeSuccess = (state, action) => {
  return updateObject(state, { charge: action.charge });
};

const getBanksSuccess = (state, action) => {
  return updateObject(state, { banks: action.data });
};

const makePaymentSuccess = (state) => {
  return updateObject(state, {
    paymentMade: true,
  });
};

const verifyTransactionSuccess = (state) => {
  return updateObject(state, {
    paymentVerified: true,
  });
};

const openTransferModal = (state, action) => {
  return updateObject(state, {
    transferAmount: action.data.amount,
    transferModalIsOpen: true,
  });
};

const closeTransferModal = (state) => {
  return updateObject(state, {
    transferModalIsOpen: false,
    transferPaymentResponse: ""
  });
};

const generateTransferReferenceSuccess = (state, action) => {
  return updateObject(state, {
    transferPaymentResponse: action.data,
  });
};

function saveGenerateReferencePayload(state, action) {
  return updateObject(state, {
    generateReferencePayload: action.data,
  });
}

function resetPaymentState(state, action) {
  return updateObject(state, { ...action.data });
}

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.PAYMENT_OPERATION_START:
      return paymentOperationStart(state);
    case actionTypes.PAYMENT_OPERATION_SUCCESS:
      return paymentOperationSuccess(state, action);
    case actionTypes.PAYMENT_OPERATION_FAIL:
      return paymentOperationFail(state, action);
    case actionTypes.GET_PAYMENT_CHARGE_SUCCESS:
      return getPaymentChargeSuccess(state, action);
    case actionTypes.GET_BANKS_SUCCESS:
      return getBanksSuccess(state, action);
    case actionTypes.MAKE_PAYMENT_SUCCESS:
      return makePaymentSuccess(state);
    case actionTypes.VERIFY_TRANSACTION_SUCCESS:
      return verifyTransactionSuccess(state);
    case actionTypes.OPEN_TRANSFER_PAYMENT_MODAL:
      return openTransferModal(state, action);
    case actionTypes.CLOSE_TRANSFER_PAYMENT_MODAL:
      return closeTransferModal(state);
    case actionTypes.GET_TRANSFER_REFERENCE:
      return generateTransferReferenceSuccess(state, action);
    case actionTypes.SAVE_GENERATE_TRANSFER_REFERENCE_PAYLOAD:
      return saveGenerateReferencePayload(state, action);
    case actionTypes.RESET_PAYMENT_STATE:
      return resetPaymentState(state, action);
    default:
      return state;
  }
};

export default reducer;
