import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import accountReducer from "./reducers/account";
import utilityReducer from "./reducers/appUtility";
import ajoReducer from "./reducers/ajo";
import walletReducer from "./reducers/wallet";
import notificationReducer from "./reducers/notification";
import paymentReducer from "./reducers/payment";
import savingsReducer from "./reducers/groupSavings";

const appReducer = combineReducers({
  account: accountReducer,
  utility: utilityReducer,
  ajo: ajoReducer,
  wallet: walletReducer,
  notification: notificationReducer,
  payment: paymentReducer,
  savings: savingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
  compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(store);

export { store, persistor };
