import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  message: null,
  allNotifications: [],
  notificationInfo: {
    id: null,
    type: null,
    dateCreated: null,
    data: {},
  },
  read: false,
  deleting: false,
};

const notificationOperationStart = (state) => {
  return updateObject(state, { loading: true, message: null });
};

const notificationOperationFail = (state) => {
  return updateObject(state, {
    loading: false,
    deleting: false,
  });
};

const notificationOperationSuccess = (state) => {
  return updateObject(state, {
    loading: false,
  });
};

const getAllNotificationsSuccess = (state, action) => {
  return updateObject(state, { allNotifications: action.data });
};

const viewAllNotifications = (state) => {
  return updateObject(state, { read: true });
};

const getNotificationInfoSuccess = (state, action) => {
  return updateObject(state, { notificationInfo: action.data });
};

const clearAllNotificationsSuccess = (state, action) => {
  return updateObject(state, { deleting: false, message: action.message });
};

const deleteNotificationStart = (state) => {
  return updateObject(state, { deleting: true, message: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_OPERATION_START:
      return notificationOperationStart(state);
    case actionTypes.NOTIFICATION_OPERATION_SUCCESS:
      return notificationOperationSuccess(state);
    case actionTypes.NOTIFICATION_OPERATION_FAIL:
      return notificationOperationFail(state);
    case actionTypes.GET_ALL_NOTIFICATION_SUCCESS:
      return getAllNotificationsSuccess(state, action);
    case actionTypes.VIEW_ALL_NOTIFICATION:
      return viewAllNotifications(state);
    case actionTypes.GET_NOTIFICATION_INFO:
      return getNotificationInfoSuccess(state, action);
    case actionTypes.CLEAR_ALL_NOTIFICATIONS_SUCCESS:
      return clearAllNotificationsSuccess(state, action);
    case actionTypes.DELETE_NOTIFICATION_START:
      return deleteNotificationStart(state);
    default:
      return state;
  }
};

export default reducer;
