import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  errorMessage: null,
  message: null,
  error: false,
  success: false,
  userGroupSavings: [],
  createdGroupSavings: [],
  userGroupSavingsInfo: {
    id: null,
    name: null,
    amount: 0,
    description: null,
    memberCapacity: null,
    invitationLink: null,
    startMonth: null,
    members: [],
    startMonthId: null,
    activeBalance: 0,
    bookBalance: 0,
    userTotalSavings: 0,
    groupLink: null,
  },
  groupSavingsDocuments: [],
  savingsInactiveMembers: [],
  memberTotalSavings: 0,
  groupSavingsSubAccounts: {
    groupSavingsId: null,
    groupName: null,
    SubAccounts: [],
  },
  savingsHistories: [],
  userPaymentHistory: [],
  unsettledPayments: [],
  subAccountInfo: {
    id: null,
    name: null,
    description: null,
    paymentType: null,
    paymentFrequency: null,
    amountPayable: 0,
    totalAmountContributed: 0,
    numberOfPayments: 0,
    memberPayments: [],
  },
  pendingRequests: [],
  liquidationRequests: [],
  groupSavingsCreated: false,
  validatorSet: false,
  memberDeactivated: false,
  requestValidated: false,
  addGroupMemberModalIsOpen: false,
  adminRequestFundModalIsOpen: false,
  savingsMemberIsAdded: false,
  fundRequested: false,
  createSubAccountModalIsOpen: false,
  memberApproved: false,
  pendingGroupSavings: [],
};

const createGroupSavingsSuccess = (state, action) => {
  return updateObject(state, { createdGroupSavings: action.data, groupSavingsCreated: true });
};

const savingsOperationStart = (state) => {
  return updateObject(state, {
    loading: true,
    errorMessage: null,
    message: null,
    error: false,
    success: false,
  });
};

const savingsOperationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorMessage: action.error,
    error: true,
    success: false,
  });
};

const savingsOperationSuccess = (state) => {
  return updateObject(state, {
    loading: false,
    errorMessage: null,
    error: false,
    success: true,
  });
};

function approveGroupMemberSuccess(state) {
  return updateObject(state, { memberApproved: true });
}

function addGroupSavingsMemberSuccess(state) {
  return updateObject(state, { savingsMemberIsAdded: true });
}

function requestFundSuccess(state) {
  return updateObject(state, { fundRequested: true });
}

function setGroupValidator(state) {
  return updateObject(state, { validatorSet: true });
}

function deactivateGroupMember(state) {
  return updateObject(state, { memberDeactivated: true });
}

function validateLiquidationRequest(state) {
  return updateObject(state, { requestValidated: true });
}

const getSavingsHistories = (state, action) => {
  return updateObject(state, { savingsHistories: action.data });
};

const getUserGroupSavingsSuccess = (state, action) => {
  return updateObject(state, { userGroupSavings: action.data });
};

const getUserGroupSavingsInfo = (state, action) => {
  return updateObject(state, { userGroupSavingsInfo: action.data });
};

const getInactiveSavingsMembers = (state, action) => {
  return updateObject(state, { savingsInactiveMembers: action.data });
};

const getMemberTotalSavings = (state, action) => {
  return updateObject(state, { memberTotalSavings: action.data.totalSavings });
};

const getGroupSavingsSubAccounts = (state, action) => {
  return updateObject(state, {
    groupSavingsSubAccounts: action.data,
  });
};

const getLiquidationRequestSuccess = (state, action) => {
  return updateObject(state, {
    liquidationRequests: action.data,
  });
};

const getUnsettledPayments = (state, action) => {
  return updateObject(state, {
    unsettledPayments: action.data,
  });
};

const getSubAccountInfo = (state, action) => {
  return updateObject(state, { subAccountInfo: action.data });
};

const getGroupDocuments = (state, action) => {
  return updateObject(state, { groupSavingsDocuments: action.data });
};

function getUserPaymentHistory(state, action) {
  return updateObject(state, { userPaymentHistory: action.data });
}

function toggleAddSavingsMemberModal(state, action) {
  return updateObject(state, { addGroupMemberModalIsOpen: action.data.status });
}

function toggleRequestSavingsFundModal(state, action) {
  return updateObject(state, { adminRequestFundModalIsOpen: action.data.status });
}

function toggleCreateSubAccountModal(state, action) {
  return updateObject(state, { createSubAccountModalIsOpen: action.data.status });
}

function resetSavingsState(state, action) {
  return updateObject(state, { ...action.data });
}

function getPendingGroupSavingsRequest(state, action) {
  return updateObject(state, { pendingGroupSavings: action.data });
}

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.CREATE_GROUP_SAVINGS_SUCCESS:
      return createGroupSavingsSuccess(state, action);
    case actionTypes.SAVINGS_OPERATION_START:
      return savingsOperationStart(state);
    case actionTypes.SAVINGS_OPERATION_FAIL:
      return savingsOperationFail(state, action);
    case actionTypes.SAVINGS_OPERATION_SUCCESS:
      return savingsOperationSuccess(state);
    case actionTypes.GET_USER_GROUP_SAVINGS_SUCCESS:
      return getUserGroupSavingsSuccess(state, action);
    case actionTypes.GET_GROUP_SAVINGS_INFO:
      return getUserGroupSavingsInfo(state, action);
    case actionTypes.GET_INACTIVE_GROUP_SAVINGS_MEMBERS:
      return getInactiveSavingsMembers(state, action);
    case actionTypes.GET_MEMBER_TOTAL_SAVINGS:
      return getMemberTotalSavings(state, action);
    case actionTypes.GET_SAVINGS_SUB_ACCOUNTS:
      return getGroupSavingsSubAccounts(state, action);
    case actionTypes.USER_UNSETTLED_PAYMENTS:
      return getUnsettledPayments(state, action);
    case actionTypes.USER_SUBACCOUNT_INFO:
      return getSubAccountInfo(state, action);
    case actionTypes.ADMIN_GET_USER_PAYMENT_HISTORY:
      return getSavingsHistories(state, action);
    case actionTypes.GET_GROUP_DOCUMENTS:
      return getGroupDocuments(state, action);
    case actionTypes.GET_SAVINGS_LIQUIDATION_SUCCESS:
      return getLiquidationRequestSuccess(state, action);
    case actionTypes.GET_USER_PAYMENT_HISTORY:
      return getUserPaymentHistory(state, action);
    case actionTypes.SET_GROUP_VALIDATOR_SUCCESS:
      return setGroupValidator(state);
    case actionTypes.DEACTIVATE_GROUP_MEMBER_SUCCESS:
      return deactivateGroupMember(state);
    case actionTypes.VALIDATE_LIQUIDATION_REQUEST_SUCCESS:
      return validateLiquidationRequest(state);
    case actionTypes.TOGGLE_ADD_SAVINGS_MEMBER_MODAL:
      return toggleAddSavingsMemberModal(state, action);
    case actionTypes.TOGGLE_REQUEST_FUND_SAVINGS_MODAL:
      return toggleRequestSavingsFundModal(state, action);
    case actionTypes.RESET_SAVINGS_STATE:
      return resetSavingsState(state, action);
    case actionTypes.ADD_GROUP_SAVINGS_MEMBER_SUCCESS:
      return addGroupSavingsMemberSuccess(state);
    case actionTypes.REQUEST_SAVINGS_FUND_SUCCESS:
      return requestFundSuccess(state);
    case actionTypes.TOGGLE_CREATE_SUB_ACCOUNT_MODAL:
      return toggleCreateSubAccountModal(state, action);
    case actionTypes.APPROVE_GROUP_MEMBER:
      return approveGroupMemberSuccess(state);
    case actionTypes.GET_PENDING_GROUP_SAVINGS:
      return getPendingGroupSavingsRequest(state, action);
    default:
      return state;
  }
};

export default reducer;
