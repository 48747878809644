const Colors = {
  primary: "#0047CC",
  blue: "003CAC",
  accent: "#555",
  gray: "#ccc",
  light_gray: "#f2f2f2",
  white: "#fff"

};

export default Colors;
