import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  balance: 0,
  loading: false,
  message: null,
  walletTransactions: [],
  savingsBalance: 0,
  groupAjoBalance: 0,
  totalBalance: 0,
  charge: null,
  transactionRef: null,
  success: false,
  walletFunded: false,
};

const fundWalletSuccess = (state, action) => {
  return updateObject(state, {
    charge: action.data.extraCharges,
    transactionRef: action.data.transactionRef,
    walletFunded: true,
  });
};

const walletOperationStart = (state) => {
  return updateObject(state, {
    loading: true,
    message: null,
    success: false,
  });
};

const walletOperationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.error,
    success: false,
  });
};

const walletOperationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.message,
  });
};

const getWalletTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    walletTransactions: action.data,
  });
};

const getWalletBalanceSuccess = (state, action) => {
  return updateObject(state, { balance: action.data.balance });
};

const getAllUserBalanceSuccess = (state, action) => {
  return updateObject(state, {
    totalBalance: action.data.totalBalance,
    groupAjoBalance: action.data.groupAjoBalance,
    savingsBalance: action.data.savingsBalance,
  });
};

function resetWalletState(state, action) {
  return updateObject(state, { ...action.data });
}

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.WALLET_OPERATION_START:
      return walletOperationStart(state);
    case actionTypes.WALLET_OPERATION_FAIL:
      return walletOperationFail(state, action);
    case actionTypes.WALLET_OPERATION_SUCCESS:
      return walletOperationSuccess(state, action);
    case actionTypes.GET_WALLET_BALANCE_SUCCESS:
      return getWalletBalanceSuccess(state, action);
    case actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS:
      return getWalletTransactionsSuccess(state, action);
    case actionTypes.GET_ALL_USER_BALANCE:
      return getAllUserBalanceSuccess(state, action);
    case actionTypes.FUND_WALLET_SUCCESS:
      return fundWalletSuccess(state, action);
    case actionTypes.RESET_WALLET_STATE:
      return resetWalletState(state, action);
    default:
      return state;
  }
};

export default reducer;
