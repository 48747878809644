import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import Card from "../../Components/Card";
import Colors from "../../Constants/color";
import * as actions from "../../store/actions";
import RandomAvater from "../../Components/common/random_avatar";

const RecentGroupSavings = (props) => {
  const dispatch = useDispatch();

  const goToSavingsGroup = () => {
    props.history.push(`/savings/${props.id}`, {
      id: props.id,
    });
    dispatch(actions.closeTopDrawer());
  };

  const membersNaming = props.memberCapacity > 1 ? "members" : "member";

  return (
    <div style={{ marginBottom: "1.5em", cursor: "pointer" }}>
      <Card style={styles.card} onClick={goToSavingsGroup}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="mr-3">
              <RandomAvater words={props.name} type={"savings"} />
            </div>
            <div>
              <p style={styles.text}>{props.name}</p>
              <p style={{ color: Colors.accent, fontSize: "0.7rem" }}>
                {props.memberCapacity} {membersNaming}
              </p>
            </div>
          </div>
          <div>
            <p onClick={goToSavingsGroup} style={{ color: Colors.primary }}>
              view
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

const styles = {
  text: {
    color: Colors.accent,
    textTransform: "uppercase",
    fontSize: "0.75rem",
    fontWeight: "bold",
    marginBottom: "2px",
  },
  card: { background: "#F4FAFF", minHeight: "", borderRadius: "5px" },
};

export default withRouter(RecentGroupSavings);
