import React from "react";

const Card = (props) => {
  return (
    <div
      style={{ ...styles.card, ...props.style }}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const styles = {
  card: {
    borderRadius: "20px",
    margin: "5px",
    padding: "1rem",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,.3)",
    backgroundColor: "white",
    color: "#ccc",
    minHeight: "9rem",
    boxSizing: "border-box",
  },
};
export default Card;
