import { toast, cssTransition } from "react-toastify";
import "./style.css";

toast.configure({ hideProgressBar: true, limit: 1 });

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  duration: [1000, 1800],
});

const successMessage = (message) => {
  toast.success(message, { ...toastObject, toastId: message });
};

const infoMessage = (message) => {
  toast.info(message, { ...toastObject, toastId: message });
};

const errorMessage = (error) => {
  if (error.response) {
    toast.error(error.response.data.message, { ...toastObject });
  } else if (error.request) {
    toast.error("Unable to connect, please try again", { ...toastObject });
  } else {
    toast.error("Something went wrong", { ...toastObject });
  }
};

const toastObject = { transition: Zoom };

export { successMessage, errorMessage, infoMessage };
