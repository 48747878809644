import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  errorMessage: null,
  message: null,
  loading: false,
  iconLoading: false,
  error: false,
  success: false,
  email: null,
  password: null,
  token: null,
  firstName: null,
  lastName: null,
  id: null,
  roles: null,
  phone: null,
  authenticated: false,
  avatar: null,
  genderName: null,
  stateName: null,
  residentialAddress: null,
  officeAddress: null,
  uploading: false,
  loadedUserInfo: false,
  monthlySalaray: null,
  dateForSalary: null,
  bankAccountNumber: null,
  bankName: null,
  bankCode: null,
  cardDetails: [],
  bvn: null,
  hasAjoAccess: false,
  utilitybillUrl: null,
  offerLetterUrl: null,
  phoneNumberConfirmed: false,
  bankAccountStatementUrl: null,
  bvnVerified: false,
};

const getTokenStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    iconLoading: true,
    message: null,
    errorMessage: null,
    error: false,
    success: false,
  });
};

const getTokenSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    iconLoading: false,
    message: null,
    errorMessage: null,
    error: false,
    success: true,
    token: action.data.token,
    id: action.data.id,
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    email: action.data.email,
    phone: action.data.phone,
    roles: action.data.roles,
    authenticated: true,
  });
};

const getTokenFailure = (state, action) => {
  return updateObject(state, {
    message: action.error,
    errorMessage: action.error,
    token: null,
    loading: false,
    iconLoading: false,
    success: false,
    error: true,
    firstName: null,
    authenticated: false,
  });
};

const resetAuthState = (state, action) => {
  return updateObject(state, {
    message: null,
    errorMessage: null,
    token: null,
    loading: false,
    iconLoading: false,
    success: false,
    error: false,
    firstName: null,
    authenticated: false,
  });
};

const registrationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    message: null,
    errorMessage: null,
    error: false,
    success: false,
  });
};

const registrationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    iconLoading: true,
    message: action.data.message,
    errorMessage: null,
    error: false,
    success: true,
  });
};

const registrationFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
    iconLoading: true,
    message: action.error,
    errorMessage: action.error,
    error: true,
    success: false,
  });
};

const operationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    message: null,
    error: false,
    success: false,
  });
};

const operationFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
    iconLoading: false,
    message: action.error,
    errorMessage: action.error,
    error: true,
    success: false,
    loadedUserInfo: false,
  });
};

const operationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.message,
    error: false,
    loadedUserInfo: true,
    success: true,
  });
};

const getUserInfoSuccess = (state, action) => {
  return updateObject(state, {
    id: action.data.id,
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    email: action.data.email,
    phone: action.data.phone,
    roles: action.data.roles,
    avatar: action.data.avatar,
    genderName: action.data.genderName,
    residentialAddress: action.data.residentialAddress,
    stateName: action.data.stateName,
    stateId: action.data.stateId,
    officeAddress: action.data.officeAddress,
    monthlySalaray: action.data.monthlySalaray,
    dateForSalary: action.data.dateForSalary,
    loadedUserInfo: true,
    bankName: action.data.bankName,
    bankCode: action.data.bankCode,
    bankAccountNumber: action.data.bankAccountNumber,
    cardDetails: action.data.cardDetailsDtos,
    bvn: action.data.bvn,
    hasAjoAccess: action.data.hasAjoAccess,
    loading: false,
    phoneNumberConfirmed: action.data.phoneNumberConfirmed,
    utilitybillUrl: action.data.utilitybillUrl,
    bankAccountStatementUrl: action.data.bankAccountStatementUrl,
    offerLetterUrl: action.data.offerLetterUrl,
    bvnVerified: action.data.bvnVerified,
  });
};

const operationCompleted = (state, action) => {
  return updateObject(state, {
    loading: false,
    iconLoading: false,
    message: null,
    errorMessage: null,
    error: false,
    success: true,
  });
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.GET_TOKEN_START:
      return getTokenStart(state, action);
    case actionTypes.GET_TOKEN_SUCCESS:
      return getTokenSuccess(state, action);
    case actionTypes.GET_TOKEN_FAILURE:
      return getTokenFailure(state, action);
    case actionTypes.RESET_AUTH_STATE:
      return resetAuthState(state, action);
    case actionTypes.AUTH_LOGOUT:
      return initialState;
    case actionTypes.REGISTRATION_START:
      return registrationStart(state, action);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state, action);
    case actionTypes.REGISTRATION_FAILURE:
      return registrationFailure(state, action);
    case actionTypes.OPERATION_START:
      return operationStart(state, action);
    case actionTypes.OPERATION_FAILURE:
      return operationFailure(state, action);
    case actionTypes.GET_USER_INFO_SUCCESS:
      return getUserInfoSuccess(state, action);
    case actionTypes.ACCOUNT_OPERATION_SUCCESS:
      return operationSuccess(state, action);
    case actionTypes.OPERATION_COMPLETED:
      return operationCompleted(state, action);
    default:
      return state;
  }
};

export default reducer;
