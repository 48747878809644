import apiCall from "axios";

import * as actionTypes from "./actionTypes";
import { errorMessage, infoMessage, successMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fundWallet = (amount) => {
  return async (dispatch, getState) => {
    dispatch(walletOperationStart());

    const data = {
      amount,
      email: getState().account.email,
    };

    try {
      let res = await apiCall.post(`${baseUrl}/api/Wallet/FundWalletLenco`, data);

      if (!res) {
        dispatch(walletOperationFail());
        infoMessage("Unable to fund wallet at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(walletOperationSuccess());
        dispatch(fundWalletSuccess(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(walletOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(walletOperationFail());
      errorMessage(error);
    }
  };
};

export const getAllUserBalances = () => {
  return async (dispatch, getState) => {
    try {
      let res = await apiCall.get(
        `${baseUrl}/api/Wallet/get-user-balance?email=${getState().account.email}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(walletOperationSuccess(res.data.message));
        dispatch(getAllUserBalancesSuccess(res.data.returnedObject));
      } else {
        dispatch(walletOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(walletOperationFail(error.response.data.message));
      }
    }
  };
};

export const getWalletBalance = () => {
  return async (dispatch, getState) => {
    try {
      let res = await apiCall.get(
        `${baseUrl}/api/Wallet/GetWalletBalance/?email=${getState().account.email}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(walletOperationSuccess(res.data.message));
        dispatch(getWalletBalanceSuccess(res.data.returnedObject));
      } else {
        dispatch(walletOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(walletOperationFail(error.response.data.message));
      }
    }
  };
};

export const getUserWalletTransactions = () => {
  return async (dispatch, getState) => {
    dispatch(walletOperationStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/Wallet/GetUserWalletTransactions?email=${getState().account.email}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(walletOperationSuccess(res.data.message));
        dispatch(getUserWalletTransactionsSuccess(res.data.returnedObject));
      } else {
        dispatch(walletOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(walletOperationFail(error.response.data.message));
      }
    }
  };
};

export const getAllUserBalancesSuccess = (data) => {
  return { type: actionTypes.GET_ALL_USER_BALANCE, data };
};

export const getWalletBalanceSuccess = (data) => {
  return {
    type: actionTypes.GET_WALLET_BALANCE_SUCCESS,
    data,
  };
};

export const getUserWalletTransactionsSuccess = (data) => {
  return {
    type: actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS,
    data,
  };
};

export const walletOperationStart = () => {
  return {
    type: actionTypes.WALLET_OPERATION_START,
  };
};

export const walletOperationSuccess = (message) => {
  return {
    type: actionTypes.WALLET_OPERATION_SUCCESS,
    message,
  };
};

export const walletOperationFail = (error) => {
  return {
    type: actionTypes.WALLET_OPERATION_FAIL,
    error,
  };
};

export const fundWalletSuccess = (data) => {
  return {
    type: actionTypes.FUND_WALLET_SUCCESS,
    data,
  };
};

export const resetWalletState = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_WALLET_STATE, data });
  };
};
