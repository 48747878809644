import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// import dotenv from "dotenv";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import "antd/dist/antd.css";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { store, persistor } from "./store/configureStore";
import * as actions from "../src/store/actions";
import { infoMessage } from "../src/templates/api_message";
import ErrorBoundary from "../src/error_boundary";

// dotenv.config();

axios.interceptors.request.use(
  function (config) {
    const token = store.getState().account.token;
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      store.dispatch(actions.logout());

      infoMessage("Your session has expired, please log in to continue");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </PersistGate>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
