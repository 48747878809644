import React, { Component } from "react";

import ErrorComponent from "./Components/common/error_page";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent text="Please try again later." />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
