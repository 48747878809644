import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/actions";
import * as ROUTES from "./Constants/routes";
import SharedComponents from "./shared";

class App extends React.Component {
  render() {
    const AuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.props.authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    const NoAuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.props.authenticated ? (
            <Route
              render={() => {
                return <Redirect to="/dashboard" />;
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );

    return (
      <>
        <SharedComponents />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <NoAuthRoute path="/" component={ROUTES.LandingPage} exact />
            <NoAuthRoute path="/login" component={ROUTES.Login} />
            <NoAuthRoute path="/signup" component={ROUTES.SignUp} />
            <NoAuthRoute path="/verifyMail" component={ROUTES.VerifyMail} />
            <NoAuthRoute
              path="/send_forgot_password_mail"
              component={ROUTES.SendResetPasswordMail}
            />
            <Route path="/terms" component={ROUTES.Terms} />
            <AuthRoute path="/change_password" component={ROUTES.ChangePassword} />
            <AuthRoute path="/dashboard" component={ROUTES.Dashboard} exact />
            <AuthRoute path="/dashboard/profile" component={ROUTES.Profile} exact />
            <AuthRoute path="/dashboard/profile/edit" component={ROUTES.ProfileEdit} />
            <AuthRoute path="/dashboard/wallet/" component={ROUTES.Wallet} />
            <AuthRoute path="/dashboard/savings" component={ROUTES.GroupSavings} exact />
            <AuthRoute path="/dashboard/activity" component={ROUTES.ActivityPage} exact />
            <AuthRoute path="/dashboard/activity/:id" component={ROUTES.ActivitySinglePage} />
            <Route path="/ajo/join-group/:group_id" component={ROUTES.AjoJoinGroup} exact />
            <Route path="/forgot_password" component={ROUTES.ResetPassword} exact />
            <Route path="/contact-us" component={ROUTES.ContactPage} exact />
            <Route path="/faqs" component={ROUTES.FAQ} exact />
            <AuthRoute path="/ajo/group/invite" component={ROUTES.AjoInvite} exact />
            <AuthRoute path="/dashboard/ajo/payment" component={ROUTES.AjoPaymentPage} />
            <AuthRoute
              path="/dashboard/sub-account/payment"
              component={ROUTES.SubAccountPaymentPage}
            />
            <AuthRoute path="/savings/documents" component={ROUTES.SavingsDocument} exact />
            <AuthRoute path="/savings/group/invite" component={ROUTES.SavingsInvite} exact />
            <AuthRoute path="/dashboard/ajo" component={ROUTES.Ajo} />
            <AuthRoute path="/ajo/user/groups" component={ROUTES.AllUserAjoGroups} />
            <AuthRoute path="/ajo/group" component={ROUTES.ShowAjosDrawer} exact />
            <AuthRoute path="/ajo/:id" component={ROUTES.AjoSinglePage} exact />
            <AuthRoute
              path="/ajo/:id/payment-requests"
              component={ROUTES.AjoPaymentRequests}
              exact
            />
            <AuthRoute path="/ajo/groups/all" component={ROUTES.AllAjoGroups} />
            <AuthRoute path="/ajo/user/transactions" component={ROUTES.UserAjoTransactions} />
            <Route path="/savings/join-group/:group_id" exact component={ROUTES.SavingsJoinGroup} />
            <AuthRoute path="/savings/:id" component={ROUTES.GroupSavingsInfoPage} exact />
            <AuthRoute path="/savings/user/all" component={ROUTES.AllUserGroupSavings} />
            <AuthRoute
              path="/savings/:id/unsettled-payments"
              component={ROUTES.UnsettledPayments}
            />
            <AuthRoute path="/savings/sub-account/:id" component={ROUTES.SubAccountInfo} exact />
            <AuthRoute path="/savings/:id/members" component={ROUTES.GroupSavingsMembers} exact />
            <AuthRoute
              path="/savings/:id/members-payments"
              component={ROUTES.MembersPaymentHistory}
              exact
            />
            <AuthRoute
              path="/savings/:id/my-payments"
              component={ROUTES.UserSavingsPayments}
              exact
            />
            <AuthRoute
              path="/savings/:id/liquidation-requests"
              component={ROUTES.LiquidationRequests}
              exact
            />
            <AuthRoute path="/savings/:id/sub-accounts" component={ROUTES.SubAccounts} exact />
            <AuthRoute path="/dashboard/corporate-account" component={ROUTES.CorporateAccount} />
            <Route component={ROUTES.PageNotFound} />
          </Switch>
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.account.authenticated,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    authCheckState: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(App);
