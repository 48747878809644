import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../store/actions";
import AvailableAjo from "../Home/available_ajo";
import Colors from "../../Constants/color";
import Card from "../../Components/Card";
import Button from "../../Components/common/Button";

const CreateAjo = (props) => {
  const ajo = useSelector((state) => state.ajo);

  const { availableAjoGroups } = ajo;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAvailableAjoGroups());
  }, [dispatch]);

  const fetchAvailableAjoGroups = () => {
    if (availableAjoGroups.length <= 0) {
      return <div style={styles.centerItem}>No available ajo group</div>;
    }

    const ajoGroups = [...availableAjoGroups];
    const recentAjoGroups = ajoGroups.reverse();
    const splicedRecentAjoGroups = recentAjoGroups.slice(0, 10);

    return splicedRecentAjoGroups.map((ajoGroup) => (
      <AvailableAjo
        memberCapacity={ajoGroup.memberCapacity}
        name={ajoGroup.name}
        id={ajoGroup.id}
        key={ajoGroup.id}
        members={ajoGroup.members}
        description={ajoGroup.description}
      />
    ));
  };

  const createAjoHandler = () => {
    props.history.push(`/dashboard/ajo`);

    dispatch(actions.closeAjoDrawer());
  };

  const viewAllAjoGroups = () => {
    props.history.push("/ajo/groups/all");

    dispatch(actions.closeAjoDrawer());
  };

  return (
    <div className="container mt-3">
      <p style={styles.ajoText}>Create Ajo</p>
      <div className="d-flex justify-content-between mt-3">
        <Card style={styles.card}>
          <p style={styles.ajoLink} onClick={createAjoHandler}>
            Group Ajo
          </p>
        </Card>
        <Card style={styles.card}>
          <p style={styles.ajoLink} onClick={createAjoHandler}>
            Individual Ajo
          </p>
        </Card>
      </div>
      <div className="mt-4">
        <p style={styles.ajoText}>AJO GROUPS</p>
        <hr />
      </div>
      <div className="mt-3">
        {fetchAvailableAjoGroups()}
        <Button style={{ background: "#F1F3F8", cursor: "pointer" }}>
          <div style={{ color: Colors.primary }} onClick={viewAllAjoGroups}>
            See all
          </div>
        </Button>
      </div>
    </div>
  );
};
const styles = {
  ajoText: {
    color: Colors.primary,
    fontSize: "1.6rem",
    fontWeight: "bold",
  },
  ajoLink: {
    color: Colors.primary,
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  card: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "",
    height: "80px",
    borderRadius: "5px",
  },
};
export default withRouter(CreateAjo);
