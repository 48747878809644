import * as actionTypes from "./actionTypes";
import apiCall from "axios";
import { errorMessage, infoMessage, successMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getPaymentCharge = (amount) => {
  return async (dispatch, getState) => {
    try {
      const res = await apiCall.get(`${baseUrl}/api/Payment/PaymentCharge?amount=${amount}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(getPaymentChargeSuccess(res.data.returnedObject));
      } else {
        dispatch(paymentOperationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(paymentOperationFail(error.response.data.message));
      }
    }
  };
};

export const verifyTransaction = (verificationData) => {
  return async (dispatch) => {
    dispatch(paymentOperationStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/Payment/verifyTransaction`, verificationData);

      if (!res) {
        infoMessage("Unable to verify transaction, please try again later");
        dispatch(paymentOperationFail());
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(paymentOperationSuccess());
        dispatch(verifyTransactionSuccess(res.data.returnedObject));
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail(error.response.data.message));
      errorMessage(error);
    }
  };
};

export const verifyFirstTransaction = (verificationData) => {
  return async (dispatch) => {
    dispatch(paymentOperationStart());

    try {
      const res = await apiCall.post(
        `${baseUrl}/api/Payment/verifyFirstTransaction`,
        verificationData
      );

      if (!res) {
        infoMessage("Unable to verify transaction, please try again later");
        dispatch(paymentOperationFail());
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(paymentOperationSuccess());
        dispatch(verifyTransactionSuccess(res.data.returnedObject));
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail(error.response.data.message));
      errorMessage(error);
    }
  };
};

export const makePayment = (paymentData) => {
  return async (dispatch) => {
    dispatch(paymentOperationStart());
    try {
      const res = await apiCall.post(`${baseUrl}/api/Payment/make-payment`, paymentData);

      if (!res) {
        infoMessage("Unable to complete payment, please try again later");
        dispatch(paymentOperationFail());
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(paymentOperationSuccess());
        dispatch(makePaymentSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail());
      errorMessage(error);
    }
  };
};

export const getBanks = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Payment/getbanks`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getBanksSuccess(res.data.returnedObject.data));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const subAccountPayment = (paymentData) => {
  return async (dispatch) => {
    dispatch(paymentOperationStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/GroupSavings/SubAccountPaymentLenco`, paymentData);

      if (!res) {
        infoMessage("Unable to complete payment, please try again later");
        dispatch(paymentOperationFail());
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(paymentOperationSuccess());
        dispatch(makePaymentSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail(error.response.data.message));
      errorMessage(error);
    }
  };
};

export const generateTransferReference = (paymentData) => {
  return async (dispatch, getState) => {
    dispatch(paymentOperationStart());

    try {
      paymentData = { 
        amount: paymentData.amount,
        subAccountId: paymentData.categoryPaymentId,
        monthId: paymentData.monthId , userId: getState().account.id };

      // const res = await apiCall.post(`${baseUrl}/api/Payment/payment-with-transfer`, paymentData);
      const res = await apiCall.post(`${baseUrl}/api/GroupSavings/SubAccountPaymentLenco`, paymentData);

      if (!res) {
        infoMessage("Unable to generate reference at the moment, please try again later");
        dispatch(paymentOperationFail());
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(paymentOperationSuccess());
        dispatch(transferReferenceGeneration(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail(error.response.data.message));
      errorMessage(error);
    }
  };
};

export const makePaymentWithWallet = (payload) => {
  return async (dispatch, getState) => {
    dispatch(paymentOperationStart());

    try {
      const data = { ...payload, userId: getState().account.id };

      let res = await apiCall.post(`${baseUrl}/api/Wallet/make-payment-with-wallet`, data);

      if (!res) {
        dispatch(paymentOperationFail());
        infoMessage("Unable to generate reference at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(paymentOperationSuccess());
        dispatch(makePaymentSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(paymentOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(paymentOperationFail());
      errorMessage(error);
    }
  };
};

export const generateFundWalletReference = (payload) => {
  return async (dispatch, getState) => {
    dispatch(fundWalletOperationStart());

    const data = { amount: payload, email: getState().account.email };

    try {
      let res = await apiCall.post(`${baseUrl}/api/Wallet/FundWalletLenco`, data);

      if (!res) {
        dispatch(fundWalletOperationFail());
        infoMessage("Unable to make payment at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(fundWalletOperationSuccess());
        dispatch(transferReferenceGeneration(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(fundWalletOperationFail(res.data.message));
        infoMessage(res.data.message);
      }

    } catch (error) {
      dispatch(fundWalletOperationFail());
      errorMessage(error);
    }
  };
};

const transferReferenceGeneration = (data) => {
  return { type: actionTypes.GET_TRANSFER_REFERENCE, data };
};

const getBanksSuccess = (data) => {
  return { type: actionTypes.GET_BANKS_SUCCESS, data };
};


// fund wallet
const fundWalletOperationStart = () => {
  return { type: actionTypes.PAYMENT_OPERATION_START };
};

const fundWalletOperationSuccess = (message) => {
  return { type: actionTypes.PAYMENT_OPERATION_SUCCESS, message };
};

const fundWalletOperationFail = (error) => {
  return { type: actionTypes.PAYMENT_OPERATION_FAIL, error };
};
// fund wallet


const paymentOperationStart = () => {
  return { type: actionTypes.PAYMENT_OPERATION_START };
};

const paymentOperationSuccess = (message) => {
  return { type: actionTypes.PAYMENT_OPERATION_SUCCESS, message };
};

const paymentOperationFail = (error) => {
  return { type: actionTypes.PAYMENT_OPERATION_FAIL, error };
};

const verifyTransactionSuccess = (data) => {
  return { type: actionTypes.VERIFY_TRANSACTION_SUCCESS, data };
};

const getPaymentChargeSuccess = (charge) => {
  return { type: actionTypes.GET_PAYMENT_CHARGE_SUCCESS, charge };
};

const makePaymentSuccess = () => {
  return { type: actionTypes.MAKE_PAYMENT_SUCCESS };
};

export const openTransferModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_TRANSFER_PAYMENT_MODAL, data });
  };
};

export const closeTransferModal = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_TRANSFER_PAYMENT_MODAL });
  };
};

export const saveTransferReferencePayload = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SAVE_GENERATE_TRANSFER_REFERENCE_PAYLOAD, data });
  };
};

export const resetPaymentState = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_PAYMENT_STATE, data });
  };
};
