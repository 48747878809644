import React from "react";

import image from "../../Assets/icons/message.svg";
import CenterItem from "./centerItem";
import Button from "../../Components/common/Button";

const ErrorComponent = (props) => {
  const goBackToHomePage = async () => {
    window.location.replace("/dashboard");
  };

  return (
    <CenterItem style={{ flexDirection: "column" }}>
      <div>
        <img src={image} height="200px" alt="message" />
      </div>

      <div style={{ textAlign: "center", marginBottom: "2rem" }}>
        <h5 style={{ color: "red" }}>Oops! Something went wrong </h5>
        <p>{props.text}</p>
      </div>

      <Button onClick={() => goBackToHomePage()}>Back to dashboard</Button>
    </CenterItem>
  );
};

export default ErrorComponent;
