import * as actionTypes from "./actionTypes";
import qs from "qs";
import apiCall from "axios";

import { errorMessage, successMessage, infoMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const createExistingGroupAjo = (ajoCreateData) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      ajoCreateData = { ...ajoCreateData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/GroupAjo/CreateExistingGroupAjo`, ajoCreateData);

      if (!res) {
        dispatch(ajoOperationFail());
        infoMessage("Unable to create a group ajo at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        res.data.message = "Operation Successful!";
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(createExistingGroupAjoSuccess(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const createIndividualAjo = (ajoCreateData) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      ajoCreateData = { ...ajoCreateData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/GroupAjo/CreateIndividualAjo`, ajoCreateData);

      if (!res) {
        dispatch(ajoOperationFail());
        infoMessage("Unable to create a individual ajo at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        res.data.message = "Operation Successful!";
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(createIndividualAjoSuccess(res.data.returnedObject));
        successMessage(res.data.message);
      } else {
        dispatch(ajoOperationFail());
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const joinAnExistingAjo = (ajoJoinData) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      ajoJoinData = {
        ...ajoJoinData,
        email: ajoJoinData.email ? ajoJoinData.email : getState().account.email,
      };

      let res = await apiCall.post(`${baseUrl}/api/GroupAjo/JoinAjoGroup`, ajoJoinData);

      if (!res) {
        dispatch(ajoOperationFail());
        infoMessage("Unable to join ajo at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject) {
        res.data.message = "Congratulations, you have successfully joined the group.";
        dispatch(ajoOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAjoGroupData = (ajoId) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupAjo/GetAjoById?ajoId=${ajoId}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAjoGroupDataInfo(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAjoDataById = (ajoId) => {
  return async (dispatch) => {
    dispatch(ajoOperationStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupAjo/GetAjoDataById?ajoId=${ajoId}`);

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAjoDataByIdSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAjoGroup = (ajoId) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/GroupAjo/GetAjoById?ajoId=${ajoId}&userId=${getState().account.id}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAjoGroupInfo(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const requestAjoPayment = (payload) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      payload["userId"] = getState().account.id;

      let res = await apiCall.post(`${baseUrl}/api/Payment/ajo-liquidation-request`, payload);

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess());
        dispatch(requestAjoPaymentSuccess());
        successMessage(res.data.message);
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAjoPaymentTransaction = (ajoId, memberId) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/GroupAjo/ajo-payment-history`, {
        ajoId,
        ajoMemberId: memberId,
      });

      if (res.data.isSuccessful && res.data.returnedObject) {
        dispatch(getAjoPaymentTransactionSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.returnedObject.message));
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAvailableAjoGroups = () => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      let res = await apiCall.get(`${baseUrl}/api/GroupAjo/GetAvailableAjoGroups`);

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAvailableAjoGroupsSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getUserAjoGroups = (formData) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      formData = { ...formData, email: getState().account.email };

      let res = await apiCall.get(
        `${baseUrl}/api/GroupAjo/GetUserAjoGroups?${qs.stringify(formData)}`
      );

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getUserAjoGroupsSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
      }
    } catch (error) {
      errorMessage(error);
      dispatch(ajoOperationFail());
    }
  };
};

export const getAjoMemberContribution = (id) => {
  return async (dispatch, getState) => {
    try {
      let email = getState().account.email;

      let res = await apiCall.get(
        `${baseUrl}/api/GroupAjo/member-contribution/${id}?email=${email}`
      );

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAjoMemberContributionSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const getAjoLiquidationRequests = (id) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      let userId = getState().account.id;

      let res = await apiCall.get(
        `${baseUrl}/api/GroupAjo/get-ajo-liquidation-request-list/${id}/${userId}`
      );

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess(res.data.message));
        dispatch(getAjoLiquidationRequestsSuccess(res.data.returnedObject));
      } else {
        dispatch(ajoOperationFail(res.data.message));
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

export const inviteAjoMember = (ajoInviteData) => {
  return async (dispatch, getState) => {
    dispatch(ajoOperationStart());

    try {
      ajoInviteData = { ...ajoInviteData, userEmail: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/GroupAjo/SendAjoInvitationLink`, ajoInviteData);

      if (!res) {
        dispatch(ajoOperationFail());
        infoMessage("Unable to send invitation link at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(ajoOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(ajoOperationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(ajoOperationFail());
      errorMessage(error);
    }
  };
};

const createExistingGroupAjoSuccess = (data) => {
  return { type: actionTypes.CREATE_EXISTING_GROUP_AJO_SUCCESS, data };
};

function requestAjoPaymentSuccess() {
  return { type: actionTypes.REQUEST_AJO_PAYMENT };
}

const getAjoLiquidationRequestsSuccess = (data) => {
  return { type: actionTypes.GET_AJO_LIQUIDATION_REQUESTS, data };
};

export const getAjoGroupInfo = (data) => {
  return { type: actionTypes.GET_AJO_GROUP_INFO, data };
};

const getAjoGroupDataInfo = (data) => {
  return { type: actionTypes.GET_AJO_GROUP_DATA, data };
};

const getAjoDataByIdSuccess = (data) => {
  return { type: actionTypes.GET_AJO_DATA_BY_ID_SUCCESS, data };
};

const getAvailableAjoGroupsSuccess = (data) => {
  return { type: actionTypes.GET_AVAILABLE_AJO_GROUPS_SUCCESS, data };
};

const createIndividualAjoSuccess = (data) => {
  return { type: actionTypes.CREATE_INDIVIDUAL_AJO_SUCCESS, data };
};

const getAjoPaymentTransactionSuccess = (data) => {
  return { type: actionTypes.GET_AJO_PAYMENT_TRANSACTIONS, data };
};

export const resetAjoState = (data) => {
  return { type: actionTypes.RESET_AJO_STATE, data };
};

const ajoOperationStart = () => {
  return { type: actionTypes.AJO_OPERATION_START };
};

const ajoOperationSuccess = (message) => {
  return { type: actionTypes.AJO_OPERATION_SUCCESS, message };
};

const ajoOperationFail = (error) => {
  return { type: actionTypes.AJO_OPERATION_FAIL, error };
};

const getAjoMemberContributionSuccess = (data) => {
  return { type: actionTypes.AJO_MEMBER_CONTRIBUTION, data };
};

const getUserAjoGroupsSuccess = (data) => {
  return { type: actionTypes.GET_USER_AJO_GROUPS_SUCCESS, data };
};

export const toggleRequestAjoPaymentModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_REQUEST_AJO_FUND_MODAL, data });
  };
};
