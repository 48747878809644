import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer } from "antd";
import * as actions from "../../store/actions";

const SideDrawer = (props) => {
  const sideDrawerIsOpen = useSelector((state) => state["utility"].sideDrawerIsOpen);
  const dispatch = useDispatch();

  const onCloseSideDrawer = () => {
    dispatch(actions.closeSideDrawer());
  };

  return (
    <Drawer onClose={onCloseSideDrawer} width="80%" visible={sideDrawerIsOpen} placement="left">
      {props.children}
    </Drawer>
  );
};

export default SideDrawer;
