import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  genders: [],
  fileUrl: null,
  ajoTypes: [],
  feeTypes: [],
  paymentChannels: [],
  years: [],
  months: [],
  paymentCategoryTypes: [],
  paymentFrequencies: [],
  sideDrawerIsOpen: false,
  topDrawerIsOpen: false,
  ajoDrawerIsOpen: false,
  viewProfileModalIsOpen: false,
  joinGroupParams: {
    group_id: null,
    pathname: null,
    joinGroup: false,
    groupType: null,
  },
  states: [],
  memberName: null,
  memberEmail: null,
  faqs: [],
};

const getStateSuccess = (state, action) => {
  return updateObject(state, {
    states: action.data,
  });
};

const getGendersSuccess = (state, action) => {
  return updateObject(state, {
    genders: action.data,
  });
};

const openTopDrawer = (state) => {
  return updateObject(state, {
    topDrawerIsOpen: true,
  });
};

const closeTopDrawer = (state) => {
  return updateObject(state, {
    topDrawerIsOpen: false,
  });
};

const closeSideDrawer = (state) => {
  return updateObject(state, {
    sideDrawerIsOpen: false,
  });
};

const saveJoinGroupParam = (state, action) => {
  return updateObject(state, { joinGroupParams: action.data });
};

const openSideDrawer = (state) => {
  return updateObject(state, {
    sideDrawerIsOpen: true,
  });
};

const closeAjoDrawer = (state) => {
  return updateObject(state, {
    ajoDrawerIsOpen: false,
  });
};

const openAjoDrawer = (state) => {
  return updateObject(state, {
    ajoDrawerIsOpen: true,
  });
};

const openViewProfileModal = (state, action) => {
  return updateObject(state, {
    viewProfileModalIsOpen: true,
    memberName: action.data.memberName,
    memberEmail: action.data.memberEmail,
  });
};

const closeViewProfileModal = (state) => {
  return updateObject(state, {
    viewProfileModalIsOpen: false,
  });
};

const getAjoTypesSuccess = (state, action) => {
  return updateObject(state, {
    ajoTypes: action.data,
  });
};

const getPaymentChannelsSuccess = (state, action) => {
  return updateObject(state, {
    paymentChannels: action.data,
  });
};

const getYearsSuccess = (state, action) => {
  return updateObject(state, {
    years: action.data,
  });
};

const getMonthsSuccess = (state, action) => {
  return updateObject(state, {
    months: action.data,
  });
};

const getFeeTypesSuccess = (state, action) => {
  return updateObject(state, {
    feeTypes: action.data,
  });
};

const uploadFilSuccess = (state, action) => {
  return updateObject(state, {
    fileUrl: action.data.fileUrl,
  });
};

const getPaymentCategoryTypes = (state, action) => {
  return updateObject(state, {
    paymentCategoryTypes: action.data,
  });
};

const getPaymentFrequency = (state, action) => {
  return updateObject(state, {
    paymentFrequencies: action.data,
  });
};

function getFAQ(state, action) {
  return updateObject(state, {
    faqs: action.data,
  });
}

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.FETCH_STATE_SUCCESS:
      return getStateSuccess(state, action);
    case actionTypes.FETCH_GENDERS_SUCCESS:
      return getGendersSuccess(state, action);
    case actionTypes.FETCH_AJOTYPES_SUCCESS:
      return getAjoTypesSuccess(state, action);
    case actionTypes.FETCH_FEETYPES_SUCCESS:
      return getFeeTypesSuccess(state, action);
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return uploadFilSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_CHANNELS_SUCCESS:
      return getPaymentChannelsSuccess(state, action);
    case actionTypes.FETCH_YEARS_SUCCESS:
      return getYearsSuccess(state, action);
    case actionTypes.FETCH_MONTHS_SUCCESS:
      return getMonthsSuccess(state, action);
    case actionTypes.OPEN_SIDEBAR:
      return openSideDrawer(state);
    case actionTypes.CLOSE_SIDEBAR:
      return closeSideDrawer(state);
    case actionTypes.OPEN_TOP_DRAWER:
      return openTopDrawer(state);
    case actionTypes.CLOSE_TOP_DRAWER:
      return closeTopDrawer(state);
    case actionTypes.OPEN_AJO_DRAWER:
      return openAjoDrawer(state);
    case actionTypes.CLOSE_AJO_DRAWER:
      return closeAjoDrawer(state);
    case actionTypes.GET_PAYMENT_CATEGORY_TYPE_SUCCESS:
      return getPaymentCategoryTypes(state, action);
    case actionTypes.GET_PAYMENT_FREQUENCIES_SUCCESS:
      return getPaymentFrequency(state, action);
    case actionTypes.SAVE_JOIN_GROUP_PARAM:
      return saveJoinGroupParam(state, action);
    case actionTypes.OPEN_VIEW_PROFILE_MODAL:
      return openViewProfileModal(state, action);
    case actionTypes.CLOSE_VIEW_PROFILE_MODAL:
      return closeViewProfileModal(state);
    case actionTypes.GET_AJOPAY_FAQS:
      return getFAQ(state, action);
    default:
      return state;
  }
};

export default reducer;
