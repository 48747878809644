import { lazy } from "react";

export const Login = lazy(() => import("../Pages/login"));
export const Terms = lazy(() => import("../Pages/Terms"));
export const SignUp = lazy(() => import("../Pages/signup"));
export const Profile = lazy(() => import("../Pages/Profile/show_profile"));
export const ProfileEdit = lazy(() => import("../Pages/Profile"));
export const VerifyMail = lazy(() => import("../Pages/VerifyMail"));
export const Dashboard = lazy(() => import("../Pages/Dashboard"));
export const SendResetPasswordMail = lazy(() =>
  import("../Pages/forgotPassword/send_reset_password_link")
);
export const ResetPassword = lazy(() => import("../Pages/forgotPassword"));
export const Wallet = lazy(() => import("../Pages/Wallet"));
export const AjoSinglePage = lazy(() => import("../Pages/Services/ajo_single_page"));
export const AllAjoGroups = lazy(() => import("../Pages/Services/coming_soon"));
export const ShowAjosDrawer = lazy(() => import("../Pages/Services/show_ajo_types_drawer"));
export const AjoInvite = lazy(() => import("../Pages/Services/ajo_invite"));
export const SavingsInvite = lazy(() => import("../Pages/GroupSavings/group_savings_invite"));
export const ActivityPage = lazy(() => import("../Pages/Activity"));
export const ChangePassword = lazy(() => import("../Pages/changePassword"));
export const GroupSavings = lazy(() => import("../Pages/GroupSavings"));
export const UserAjoTransactions = lazy(() => import("../Pages/Services/ajo_transactions"));
export const GroupSavingsInfoPage = lazy(() =>
  import("../Pages/GroupSavings/group_savings_single_page")
);
export const ActivitySinglePage = lazy(() => import("../Pages/Activity/activity_detail_page"));
export const AjoPaymentPage = lazy(() => import("../Pages/Payment/ajo_payment"));
export const SubAccountPaymentPage = lazy(() => import("../Components/Payment/savings_payment"));
export const AllUserAjoGroups = lazy(() => import("../Pages/Services/all_user_ajo_groups"));
export const AllUserGroupSavings = lazy(() =>
  import("../Pages/GroupSavings/all_user_group_savings")
);
export const SubAccountInfo = lazy(() => import("../Pages/GroupSavings/user_sub_account_info"));
export const SavingsJoinGroup = lazy(() => import("../Pages/GroupSavings/join_group"));
export const AjoJoinGroup = lazy(() => import("../Pages/Services/ajo_join_invite"));
export const PageNotFound = lazy(() => import("../templates/404_page"));
export const Ajo = lazy(() => import("../Pages/Services/create_ajo"));
export const UnsettledPayments = lazy(() => import("../Pages/GroupSavings/all_unsettled_payments"));
export const LandingPage = lazy(() => import("../Pages/HomeIndex/landing_page"));
export const GroupSavingsMembers = lazy(() => import("../Pages/GroupSavings/all_active_members"));
export const CorporateAccount = lazy(() => import("../Pages/CorporateAccount"));
export const ContactPage = lazy(() => import("../Pages/contactPage"));
export const SavingsDocument = lazy(() => import("../Pages/GroupSavings/savings_document"));
export const LiquidationRequests = lazy(() => import("../Pages/GroupSavings/liquidation_requests"));
export const SubAccounts = lazy(() => import("../Pages/GroupSavings/all_savings_sub_accounts"));
export const MembersPaymentHistory = lazy(() =>
  import("../Pages/GroupSavings/admin_payment_history_page")
);
export const UserSavingsPayments = lazy(() => import("../Pages/GroupSavings/my_payments_page"));
export const FAQ = lazy(() => import("../Pages/Faq"));
export const AjoPaymentRequests = lazy(() => import("../Pages/Services/liquidation_requests"));
