import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../Components/Card";
import Colors from "../../Constants/color";
import * as actions from "../../store/actions";
import checkIfUserIsAlreadyAMember from "../../Components/common/check_if_user_member";
import RandomAvater from "../../Components/common/random_avatar";

const AvailableAjoGroups = (props) => {
  const user = useSelector((state) => state["account"]);
  const dispatch = useDispatch();

  const userIsMember = checkIfUserIsAlreadyAMember(user, props.members);

  const goToSelectedAjoGroup = () => {
    if (!userIsMember) return;

    props.history.push(`/ajo/${props.id}`);
    dispatch(actions.closeTopDrawer());
  };

  const name = userIsMember ? props.description : props.name;

  return (
    <div style={{ marginBottom: "2rem", cursor: "pointer" }}>
      <Card style={styles.card} onClick={goToSelectedAjoGroup}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="mr-3">
              <RandomAvater words={name} type={"ajo"} />
            </div>
            <div>
              <p style={styles.ajoText}>{name}</p>
              <p style={{ color: Colors.accent, fontSize: "0.7rem" }}>
                {props.memberCapacity} members
              </p>
            </div>
          </div>
          <div>
            {userIsMember && (
              <p onClick={goToSelectedAjoGroup} style={{ color: Colors.primary }}>
                view
              </p>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

const styles = {
  ajoText: {
    color: Colors.accent,
    textTransform: "uppercase",
    fontSize: "0.75rem",
    fontWeight: "bold",
    marginBottom: "2px",
  },
  card: { background: "#F4FAFF", minHeight: "", borderRadius: "5px" },
};

export default withRouter(AvailableAjoGroups);
