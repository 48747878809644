export const GET_TOKEN_START = "GET_TOKEN_START";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAILURE = "GET_TOKEN_FAILURE";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const REGISTRATION_START = "REGISTRATION_START";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const OPERATION_FAILURE = "OPERATION_FAILURE";
export const OPERATION_START = "OPERATION_START";
export const OPERATION_COMPLETED = "OPERATION_COMPLETED";
export const ACCOUNT_OPERATION_SUCCESS = "ACCOUNT_OPERATION_SUCCESS";

export const FETCH_GENDERS_SUCCESS = "FETCH_GENDERS_SUCCESS";
export const FETCH_FEETYPES_SUCCESS = "FETCH_FEETYPES_SUCCESS";
export const FETCH_AJOTYPES_SUCCESS = "FETCH_AJOTYPES_SUCCESS";
export const FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS";
export const FETCH_MONTHS_SUCCESS = "FETCH_MONTHS_SUCCESS";
export const FETCH_PAYMENT_CHANNELS_SUCCESS = "FETCH_PAYMENT_CHANNELS_SUCCESS";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";

export const AJO_OPERATION_START = "AJO_OPERATION_START";
export const AJO_OPERATION_SUCCESS = "AJO_OPERATION_SUCCESS";
export const AJO_OPERATION_FAIL = "AJO_OPERATION_FAIL";
export const CREATE_EXISTING_GROUP_AJO_SUCCESS = "CREATE_EXISTING_GROUP_AJO_SUCCESS";
export const CREATE_INDIVIDUAL_AJO_SUCCESS = "CREATE_INDIVIDUAL_AJO_SUCCESS";
export const CREATE_GROUP_SAVINGS_SUCCESS = "CREATE_GROUP_SAVINGS_SUCCESS";
export const GET_AVAILABLE_AJO_GROUPS_SUCCESS = "GET_AVAILABLE_AJO_GROUPS_SUCCESS";
export const GET_USER_AJO_GROUPS_SUCCESS = "GET_USER_AJO_GROUPS_SUCCESS";
export const RESET_AJO_STATE = "RESET_AJO_STATE";
export const JOIN_EXISTING_AJO = "JOIN_EXISTING_AJO";
export const GET_ALL_AJO_GROUPS = "GET_ALL_AJO_GROUPS";
export const GET_AJO_GROUP_INFO = "GET_AJO_GROUP_INFO";
export const GET_AJO_PAYMENT_TRANSACTIONS = "GET_AJO_PAYMENT_TRANSACTIONS";
export const APPROVE_GROUP_MEMBER = "APPROVE_GROUP_MEMBER";
export const REJECT_GROUP_MEMBER = "REJECT_GROUP_MEMBER";
export const AJO_MEMBER_CONTRIBUTION = "AJO_MEMBER_CONTRIBUTION";
export const GET_AJO_GROUP_DATA = "GET_AJO_GROUP_DATA";
export const GET_AJO_DATA_BY_ID_SUCCESS = "GET_AJO_DATA_BY_ID_SUCCESS";
export const GET_AJO_LIQUIDATION_REQUESTS = "GET_AJO_LIQUIDATION_REQUESTS";
export const REQUEST_AJO_PAYMENT = "REQUEST_AJO_PAYMENT";
export const TOGGLE_REQUEST_AJO_FUND_MODAL = "TOGGLE_REQUEST_AJO_FUND_MODAL";

export const GET_USER_GROUP_SAVINGS_SUCCESS = "GET_USER_GROUP_SAVINGS_SUCCESS";
export const SAVINGS_OPERATION_START = "SAVINGS_OPERATION_START";
export const SAVINGS_OPERATION_SUCCESS = "SAVINGS_OPERATION_SUCCESS";
export const SAVINGS_OPERATION_FAIL = "SAVINGS_OPERATION_FAIL";
export const GET_GROUP_SAVINGS_INFO = "GET_GROUP_SAVINGS_INFO";
export const GET_INACTIVE_GROUP_SAVINGS_MEMBERS = "GET_INACTIVE_GROUP_SAVINGS_MEMBERS";
export const GET_MEMBER_TOTAL_SAVINGS = "GET_MEMBER_TOTAL_SAVINGS";
export const CREATE_SUB_ACCOUNT_SUCCESS = "CREATE_SUB_ACCOUNT_SUCCESS";
export const GET_SAVINGS_SUB_ACCOUNTS = "GET_SAVINGS_SUB_ACCOUNTS";
export const USER_UNSETTLED_PAYMENTS = "USERS_UNSETTLED_PAYMENTS";
export const GET_SAVINGS_SUB_ACCOUNTS_INFO = "GET_SAVINGS_SUB_ACCOUNTS_INFO";
export const USER_SUBACCOUNT_INFO = "USER_SUBACCOUNT_INFO";
export const SAVINGS_SEND_INVITATION = "SAVINGS_SEND_INVITATION";
export const JOIN_GROUP_SAVINGS = "JOIN_GROUP_SAVINGS";
export const ADMIN_GET_USER_PAYMENT_HISTORY = "ADMIN_GET_USER_PAYMENT_HISTORY";
export const GET_GROUP_DOCUMENTS = "GET_GROUP_DOCUMENTS";
export const GET_SAVINGS_LIQUIDATION_SUCCESS = "GET_SAVINGS_LIQUIDATION_SUCCESS";
export const GET_USER_PAYMENT_HISTORY = "GET_USER_PAYMENT_HISTORY";
export const SET_GROUP_VALIDATOR_SUCCESS = "SET_GROUP_VALIDATOR_SUCCESS";
export const DEACTIVATE_GROUP_MEMBER_SUCCESS = "DEACTIVATE_GROUP_MEMBER_SUCCESS";
export const VALIDATE_LIQUIDATION_REQUEST_SUCCESS = "VALIDATE_LIQUIDATION_REQUEST_SUCCESS";
export const TOGGLE_ADD_SAVINGS_MEMBER_MODAL = "TOGGLE_ADD_SAVINGS_MEMBER_MODAL";
export const TOGGLE_REQUEST_FUND_SAVINGS_MODAL = "TOGGLE_REQUEST_FUND_SAVINGS_MODAL";
export const TOGGLE_CREATE_SUB_ACCOUNT_MODAL = "TOGGLE_CREATE_SUB_ACCOUNT_MODAL";
export const RESET_SAVINGS_STATE = "RESET_SAVINGS_STATE";
export const ADD_GROUP_SAVINGS_MEMBER_SUCCESS = "ADD_GROUP_SAVINGS_MEMBER_SUCCESS";
export const REQUEST_SAVINGS_FUND_SUCCESS = "REQUEST_SAVINGS_FUND_SUCCESS";
export const APPROVE_SAVINGS_GROUP_MEMBER = "APPROVE_SAVINGS_GROUP_MEMBER";
export const GET_PENDING_GROUP_SAVINGS = "GET_PENDING_GROUP_SAVINGS";

export const FUND_AJO_WALLET = "FUND_AJO_WALLET";
export const WALLET_OPERATION_START = "WALLET_OPERATION_START";
export const WALLET_OPERATION_FAIL = "WALLET_OPERATION_FAIL";
export const WALLET_OPERATION_SUCCESS = "WALLET_OPERATION_SUCCESS";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_TRANSACTIONS_SUCCESS = "GET_WALLET_TRANSACTIONS_SUCCESS";
export const GET_ALL_USER_BALANCE = "GET_ALL_USER_BALANCE";
export const FUND_WALLET_SUCCESS = "FUND_WALLET_SUCCESS";
export const RESET_WALLET_STATE = "RESET_WALLET_STATE";

export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const OPEN_TOP_DRAWER = "OPEN_TOP_DRAWER";
export const CLOSE_TOP_DRAWER = "CLOSE_TOP_DRAWER";
export const OPEN_AJO_DRAWER = "OPEN_AJO_DRAWER";
export const CLOSE_AJO_DRAWER = "CLOSE_AJO_DRAWER";

export const NOTIFICATION_OPERATION_START = "NOTIFICATION_OPERATION_START";
export const NOTIFICATION_OPERATION_SUCCESS = "NOTIFICATION_OPERATION_SUCCESS";
export const NOTIFICATION_OPERATION_FAIL = "NOTIFICATION_OPERATION_FAIL";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_INFO = "GET_NOTIFICATION_INFO";
export const VIEW_ALL_NOTIFICATION = "VIEW_ALL_NOTIFICATION";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const CLEAR_ALL_NOTIFICATIONS_SUCCESS = "CLEAR_ALL_NOTIFICATIONS_SUCCESS";
export const DELETE_NOTIFICATION_START = "DELETE_NOTIFICATION_START";
export const VIEW_SINGLE_NOTIFICATION = "VIEW_SINGLE_NOTIFICATION";

export const GET_PAYMENT_CHARGE = "GET_PAYMENT_CHARGE";
export const GET_PAYMENT_CHARGE_SUCCESS = "GET_PAYMENT_CHARGE_SUCCESS";
export const PAYMENT_OPERATION_START = "PAYMENT_OPERATION_START";
export const PAYMENT_OPERATION_FAIL = "PAYMENT_OPERATION_FAIL";
export const PAYMENT_OPERATION_SUCCESS = "PAYMENT_OPERATION_SUCCESS";
export const VERIFY_TRANSACTION = "VERIFY_TRANSACTION";
export const VERIFY_TRANSACTION_SUCCESS = "VERIFY_TRANSACTION_SUCCESS";
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export const SAVE_GENERATE_TRANSFER_REFERENCE_PAYLOAD = "SAVE_GENERATE_TRANSFER_REFERENCE_PAYLOAD";
export const GET_TRANSFER_REFERENCE = "GET_TRANSFER_REFERENCE";
export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE";

export const GET_PAYMENT_CATEGORY_TYPE_SUCCESS = "GET_PAYMENT_CATEGORY_TYPE_SUCCESS";
export const GET_PAYMENT_FREQUENCIES_SUCCESS = "GET_PAYMENT_FREQUENCIES_SUCCESS";
export const SAVE_JOIN_GROUP_PARAM = "SAVE_JOIN_GROUP_PARAM";
export const OPEN_TRANSFER_PAYMENT_MODAL = "OPEN_TRANSFER_PAYMENT_MODAL";
export const CLOSE_TRANSFER_PAYMENT_MODAL = "CLOSE_TRANSFER_PAYMENT_MODAL";
export const OPEN_VIEW_PROFILE_MODAL = "OPEN_VIEW_PROFILE_MODAL";
export const CLOSE_VIEW_PROFILE_MODAL = "CLOSE_VIEW_PROFILE_MODAL";
export const UPDATE_FORM_FIELD = "UPDATE_FORM_FIELD";
export const CLEAR_FORM_FIELD = "UPDATE_FORM_FIELD";
export const GET_AJOPAY_FAQS = "GET_AJOPAY_FAQS";
