function getFAQ() {
  return [
    {
      key: 1,
      question: "What does PayHive.ng do?",
      answer:
        "PayHive.ng is a tech platform that helps provide Savings Account for social groups, Societies and Associations. It simplifies payments for collecting monetary contribution from members and helping to improve transparency in the administration of group funds.",
    },
    {
      key: 2,
      question: "Does PayHive.ng operate outside Nigeria?",
      answer: "Hi there! For now, our operating market is here in Nigeria.",
    },
    {
      key: 3,
      question: "Can I use PayHive.ng for my esusu?",
      answer:
        "PayHive.ng offers opportunity for people to carry out their rotational savings (esusu) on the platform. The service is Ajo",
    },
    {
      key: 4,
      question: "What does the Corporate Account Service mean?",
      answer:
        "We will be rolling out the service soon. It is a very interesting service option for corporate organizations. Let’s not allow the cat out of the bag yet, thank you",
    },
    {
      key: 5,
      question:
        "How can I avoid service charge fee for payments into PayHive.ng accounts or my Group Savings Account?",
      answer:
        "We have added a bank transfer option for payments. This helps take away the charges while providing you with multiple options for remittances into your accounts.",
    },
    {
      key: 6,
      question: "What are the services you offer?",
      answer: "We offer Group Savings Account, Ajo for Esusu and Corporate Account.",
    },
    {
      key: 7,
      question: "Do you have a wallet system?",
      answer:
        "Yes, we have created a Wallet System to help manage your future payments for your esusu, Group monthly dues and payment into Corporate Accounts.",
    },
    {
      key: 8,
      question: "Is PayHive.ng age-restricted?",
      answer: "To use PayHive.ng services, you must be 15 years old or older.",
    },
    {
      key: 9,
      question: "Can I open an account for my university class group?",
      answer:
        "Hi there! Yes, the Group Savings Account is just perfect for your class group. It is designed with capabilities that suit payments for your group.",
    },
    {
      key: 10,
      question: "What makes PayHive.ng different from bank accounts?",
      answer:
        "PayHive.ng is equipped with features and capabilities that you won’t find with bank accounts. PayHive.ng is ‘Group-centred' and provide seamless payment options that is just perfect for your group's financial activities.",
    },
  ];
}

export default getFAQ;
