import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";

import overview from "../../Assets/icons/overview.svg";
import activity from "../../Assets/icons/activity.svg";
import settings from "../../Assets/icons/settings.svg";
import groupAjo from "../../Assets/icons/group_ajo.svg";
import groupSavings from "../../Assets/icons/group_savings.svg";
import * as actions from "../../store/actions";
import logo from "../../Assets/Img/payhive-logo1.png";

const { Sider } = Layout;

const SideBar = (props) => {
  const dispatch = useDispatch();

  const closeSideDrawer = () => {
    dispatch(actions.closeSideDrawer());
  };

  return (
    <div>
      <Link to="/dashboard" onClick={closeSideDrawer}>
        <img src={logo} alt="logo" style={styles.logo} />
      </Link>
      <Sider width="" height={"100vh"} style={{ background: "white" }}>
        <Menu
          theme="light"
          selectedkey={[props.location.pathname]}
          defaultSelectedKeys={[props.location.pathname]}
          style={{ height: "100%", borderRight: "0" }}
        >
          <Menu.Item key="/dashboard" onClick={closeSideDrawer} style={styles.centerItem}>
            <Link to="/dashboard">
              <div className="d-flex">
                <img src={overview} alt="overview-icon" />
                <p style={styles.linkText}>Overview</p>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/activity" onClick={closeSideDrawer} style={styles.centerItem}>
            <Link to="/dashboard/activity">
              <div className="d-flex">
                <img src={activity} alt="activity-icon" />
                <p style={styles.linkText}>Activity</p>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/savings" style={styles.centerItem} onClick={closeSideDrawer}>
            <Link to="/dashboard/savings">
              <div className="d-flex">
                <img src={groupAjo} style={styles.sideImages} alt="savings-icon" />
                <p style={styles.linkText}>Group Savings Account</p>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/corporate" style={styles.centerItem} onClick={closeSideDrawer}>
            <Link to="/dashboard/corporate-account">
              <div className="d-flex">
                <img src={groupSavings} style={styles.sideImages} alt="corporate-group-icon" />
                <p style={styles.linkText}>Corporate Account</p>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/ajo/group/create" style={styles.centerItem} onClick={closeSideDrawer}>
            <Link to="/ajo/groups/all">
              <div className="d-flex">
                <img src={groupSavings} alt="group_ajo-icon" style={styles.sideImages} />
                <p style={styles.linkText}>Ajo Groups</p>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/profile" style={styles.centerItem} onClick={closeSideDrawer}>
            <Link to="/dashboard/profile">
              <div className="d-flex">
                <img src={settings} style={styles.sideImages} alt="settings-icon" />
                <p style={styles.linkText}>Settings</p>
              </div>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};

const styles = {
  centerItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  linkText: {
    fontSize: "1.2rem",
    marginLeft: "10px",
  },
  logo: {
    height: "20px",
    marginTop: "12px",
    marginBottom: "13px",
  },
  sideImages: { height: "20px" },
};

export default withRouter(SideBar);
