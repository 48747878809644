import * as actionTypes from "./actionTypes";
import apiCall from "axios";

import { errorMessage, successMessage, infoMessage } from "../../templates/api_message";

const baseUrl = process.env.REACT_APP_BASE_URL;

const operationStart = () => {
  return { type: actionTypes.OPERATION_START };
};

const getUserInfoSuccess = (data) => {
  return { type: actionTypes.GET_USER_INFO_SUCCESS, data };
};

const accountOperationSuccess = (message) => {
  return { type: actionTypes.ACCOUNT_OPERATION_SUCCESS, message };
};

const operationFail = (error) => {
  return { type: actionTypes.OPERATION_FAILURE, error };
};

const registrationSuccess = (data) => {
  return { type: actionTypes.REGISTRATION_SUCCESS, data };
};

const registrationFail = (error) => {
  return { type: actionTypes.REGISTRATION_FAILURE, error };
};

const registrationStart = () => {
  return { type: actionTypes.REGISTRATION_START };
};

const getTokenSuccess = (data) => {
  return { type: actionTypes.GET_TOKEN_SUCCESS, data };
};

const getTokenFail = (error) => {
  return { type: actionTypes.GET_TOKEN_FAILURE, error };
};

const getTokenStart = () => {
  return { type: actionTypes.GET_TOKEN_START };
};

export const resetAuthState = () => {
  localStorage.clear();

  return {
    type: actionTypes.RESET_AUTH_STATE,
  };
};

export const getToken = (authData) => {
  return async (dispatch) => {
    dispatch(getTokenStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/Account/GetToken`, authData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to login at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful && res.data.returnedObject.token) {
        localStorage.setItem("token", res.data.returnedObject.token);
        localStorage.setItem("firstName", res.data.returnedObject.firstName);
        localStorage.setItem("lastName", res.data.returnedObject.lastName);
        localStorage.setItem("phone", res.data.returnedObject.phone);
        localStorage.setItem("email", res.data.returnedObject.email);
        localStorage.setItem("roles", res.data.returnedObject.roles);
        localStorage.setItem("id", res.data.returnedObject.id);

        dispatch(getTokenSuccess(res.data.returnedObject));
      } else {
        infoMessage(res.data.message);
        dispatch(getTokenFail());
      }
    } catch (error) {
      dispatch(getTokenFail());
      errorMessage(error);
    }
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("phone");
  localStorage.removeItem("email");
  localStorage.removeItem("roles");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const phone = localStorage.getItem("phone");
    const email = localStorage.getItem("email");
    const roles = localStorage.getItem("roles");

    if (!token) {
      dispatch(logout());
    } else {
      dispatch(getTokenSuccess({ token, id, firstName, lastName, email, phone, roles }));
      dispatch(getUserInfo());
    }
  };
};

export const register = (registrationData) => {
  return async (dispatch) => {
    dispatch(registrationStart());

    try {
      let res = await apiCall.post(`${baseUrl}/api/Account/Register`, registrationData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to signup at the moment, please try again later");
        return;
      }

      if (res.data && res.data.isSuccessful && res.data.returnedObject) {
        res.data.message += " Kindly click on the email verification link sent to your email.";
        dispatch(registrationSuccess(res.data));
        successMessage(res.data.message);
      } else {
        dispatch(registrationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(registrationFail());
      errorMessage(error);
    }
  };
};

export const getUserInfo = () => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      let res = await apiCall.get(
        `${baseUrl}/api/Account/GetUserInfoById/${getState().account.id}`
      );

      if (res.data.isSuccessful && res.data.returnedObject) {
        localStorage.setItem("firstName", res.data.returnedObject.firstName);
        localStorage.setItem("lastName", res.data.returnedObject.lastName);
        localStorage.setItem("phone", res.data.returnedObject.phone);
        localStorage.setItem("email", res.data.returnedObject.email);
        localStorage.setItem("roles", res.data.returnedObject.roles);
        localStorage.setItem("id", res.data.returnedObject.id);

        dispatch(getUserInfoSuccess(res.data.returnedObject));
      } else {
        dispatch(operationFail(res.data.message));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const updateUserInfo = (userInfoData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      userInfoData = { ...userInfoData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/Account/UpdateUserInfo`, userInfoData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to update info at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(getUserInfo());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const changeAvatar = (avatarData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      avatarData = { ...avatarData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/Account/ChangeAvatar`, avatarData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to upload image at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(getUserInfo());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const sendResetPasswordMail = (payload) => {
  return async (dispatch) => {
    dispatch(operationStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/Account/SendPasswordResetLink`, payload);

      if (!res) {
        dispatch(operationFail());
        infoMessage("Unable to send password reset link at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        successMessage(res.data.message);
        dispatch(accountOperationSuccess());
      } else {
        dispatch(operationFail());
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const resetPassword = (payload) => {
  return async (dispatch) => {
    dispatch(operationStart());

    try {
      const res = await apiCall.post(`${baseUrl}/api/Account/ResetPassword`, payload);

      if (!res) {
        dispatch(operationFail());
        infoMessage("Unable to reset password at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        successMessage(res.data.message);
        dispatch(accountOperationSuccess());
      } else {
        dispatch(operationFail());
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const saveOfferLetter = (formData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      formData = { ...formData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/Account/SaveOfferLetter`, formData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to save document at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(getUserInfo());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const saveUtilityBill = (formData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      formData = { ...formData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/Account/SaveUtilityBill`, formData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to save document at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(getUserInfo());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const verifyBvn = (bvn, bankCode) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      const user = getState().account;
      const payload = {
        account_number: user.bankAccountNumber,
        bank_code: bankCode,
        first_name: user.firstName,
        last_name: user.lastName,
        bvn,
      };

      const res = await apiCall.post(`${baseUrl}/api/Payment/bvn-look-up`, payload);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to verify bvn at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        await dispatch(updateUserInfo({ bvnVerified: true }));
        dispatch(getUserInfo());
        successMessage("Your BVN has been verified successfully");
      } else {
        dispatch(operationFail());
        infoMessage(res.data.message || "BVN could not be verified. Please try again later");
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const saveBankStatement = (formData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      formData = { ...formData, email: getState().account.email };

      let res = await apiCall.post(`${baseUrl}/api/Account/SaveBankStatement`, formData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to save document at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(getUserInfo());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const changePassword = (formData) => {
  return async (dispatch, getState) => {
    dispatch(operationStart());

    try {
      formData = {
        ...formData,
        email: getState().account.email,
        userId: getState().account.id,
      };

      let res = await apiCall.post(`${baseUrl}/api/Account/ChangePassword`, formData);

      if (!res) {
        dispatch(getTokenFail());
        infoMessage("Unable to change password at the moment, please try again later");
        return;
      }

      if (res.data.isSuccessful) {
        dispatch(logout());
        dispatch(accountOperationSuccess(res.data.message));
        successMessage(res.data.message);
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};
