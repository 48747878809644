import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Drawer } from "antd";
import * as actions from "../../store/actions";

const SideDrawer = (props) => {
  const topDrawerIsOpen = useSelector((state) => state["utility"].topDrawerIsOpen);
  const dispatch = useDispatch();

  const onCloseTopDrawer = () => {
    dispatch(actions.closeTopDrawer());
  };

  return (
    <Drawer
      onClose={onCloseTopDrawer}
      visible={topDrawerIsOpen}
      placement="top"
      closable={false}
      height="100%"
    >
      <div className="ajo-top-drawer">{props.children}</div>
    </Drawer>
  );
};

export default SideDrawer;
