const checkIfUserIsAlreadyAMember = (user, members) => {
  if (!members) {
    return false;
  }

  const currentUserEmail = user.email;
  const isMember = members.find((member) => member.email === currentUserEmail);

  if (isMember) {
    return true;
  }
  return false;
};

export default checkIfUserIsAlreadyAMember;
