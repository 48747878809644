import React from "react";

const RandomAvater = (props) => {
  let { words, type } = props;

  if (!words) {
    return;
  }

  const colors = [
    "pink",
    "yellow",
    "orange",
    "lightgreen",
    "lightblue",
    "lightseagreen",
    "lightsalmon",
    "limegreen",
  ];

  const background = colors[Math.floor(Math.random() * colors.length)];
  words = words.toLowerCase();

  let firstTwoLetters;

  if (words.includes("payment for") && type === "activity") {
    const arrOfWords = words.split(" ");
    if (arrOfWords.length >= 5) {
      firstTwoLetters = arrOfWords[3].substring(0, 1) + arrOfWords[4].substring(0, 1);
    } else {
      firstTwoLetters = arrOfWords[3].substring(0, 2);
    }
  } else if (words.includes("wallet payment") && type === "activity") {
    firstTwoLetters = "WP";
  } else if (words.includes("joined group") && type === "activity") {
    firstTwoLetters = "JG";
  } else if (words.includes("transfer payment") && type === "activity") {
    firstTwoLetters = "TP";
  } else if (words.includes("wallet funding") && type === "activity") {
    firstTwoLetters = "WF";
  } else if (words.includes("fund request") && type === "wallet") {
    firstTwoLetters = "FR";
  } else if (words.includes("wallettopup") && type === "wallet") {
    firstTwoLetters = "WT";
  } else if (words.includes("-") && type === "ajo") {
    firstTwoLetters = "GA";
  } else if (words.includes("created") && type === "activity") {
    firstTwoLetters = "cg";
  } else if (props.type === "savings") {
    const nameOfAjo = words.split(" ");
    if (nameOfAjo.length >= 2) {
      firstTwoLetters = nameOfAjo[0].substring(0, 1) + nameOfAjo[1].substring(0, 1);
    } else if (nameOfAjo.length === 1) {
      firstTwoLetters = nameOfAjo[0].substring(0, 2);
    } else {
      firstTwoLetters = "sg";
    }
  } else {
    firstTwoLetters = "sg";
  }

  return (
    <div style={{ ...styles.container, background }}>
      <p>{firstTwoLetters}</p>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    color: "black",
    textTransform: "uppercase",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default RandomAvater;
