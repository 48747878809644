import * as actionTypes from "./actionTypes";
import apiCall from "axios";

import { errorMessage, infoMessage } from "../../templates/api_message";
import getFAQData from "../faq_data";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getGenders = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetGenders`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getGendersSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getAjoStatus = () => {
  return async (dispatch) => {
    try {
      await apiCall.get(`${baseUrl}/api/Utility/GetAjoStatuses`);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getAjoTypes = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetAjoTypes`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getAjoTypesSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getPaymentChannels = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetPaymentChannels`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getPaymentChannelsSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getFeeTypes = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetFeeTypes`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getFeeTypesSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getYears = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetYears`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getYearsSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getMonths = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetMonths`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getMonthsSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getStates = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetStates`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getStateSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const uploadSingleFile = (fileFormBody, field = "documents") => {
  return async (dispatch) => {
    dispatch(operationStart());

    const endpoint = field === "profile" ? "profile" : "document";

    try {
      let res = await apiCall.post(`${baseUrl}/api/file/${endpoint}`, fileFormBody);

      if (!res) {
        dispatch(operationFail());
        infoMessage("Unable to upload document at the moment, please try again later");
        return;
      }

      if (res.data && res.data.isSuccessful) {
        dispatch(operationCompleted());
        dispatch(uploadFileSuccess(res.data.returnedObject));
      } else {
        dispatch(operationFail(res.data.message));
        infoMessage(res.data.message);
      }
    } catch (error) {
      dispatch(operationFail());
      errorMessage(error);
    }
  };
};

export const getPaymentCategoryTypes = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetPaymentCategoryTypes`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getPaymentCategoryTypesSuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getPaymentFrequencies = () => {
  return async (dispatch) => {
    try {
      let res = await apiCall.get(`${baseUrl}/api/Utility/GetPaymentFrequencies`);

      if (res.data && res.data.isSuccessful) {
        dispatch(getPaymentFrequencySuccess(res.data.returnedObject));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
      }
    }
  };
};

export const getGendersSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GENDERS_SUCCESS,
    data,
  };
};

export const getStateSuccess = (data) => {
  return {
    type: actionTypes.FETCH_STATE_SUCCESS,
    data,
  };
};

export const getAjoTypesSuccess = (data) => {
  return {
    type: actionTypes.FETCH_AJOTYPES_SUCCESS,
    data,
  };
};

export const getPaymentChannelsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PAYMENT_CHANNELS_SUCCESS,
    data,
  };
};

export const getFeeTypesSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FEETYPES_SUCCESS,
    data,
  };
};

export const getYearsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_YEARS_SUCCESS,
    data,
  };
};

export const getMonthsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MONTHS_SUCCESS,
    data,
  };
};

export const operationStart = () => {
  return {
    type: actionTypes.OPERATION_START,
  };
};

export const operationCompleted = () => {
  return {
    type: actionTypes.OPERATION_COMPLETED,
  };
};

export const operationFail = (error) => {
  return {
    type: actionTypes.OPERATION_FAILURE,
    error,
  };
};

export const uploadFileSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_FILE_SUCCESS,
    data,
  };
};

export const getPaymentCategoryTypesSuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENT_CATEGORY_TYPE_SUCCESS,
    data,
  };
};

export const getPaymentFrequencySuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENT_FREQUENCIES_SUCCESS,
    data,
  };
};

export const closeSideDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_SIDEBAR });
  };
};

export const openSideDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_SIDEBAR });
  };
};

export const openTopDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_TOP_DRAWER });
  };
};

export const closeTopDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_TOP_DRAWER });
  };
};

export const openAjoDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_AJO_DRAWER });
  };
};

export const closeAjoDrawer = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_AJO_DRAWER });
  };
};

export const saveJoinGroupParam = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SAVE_JOIN_GROUP_PARAM, data });
  };
};

export const openViewProfileModal = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPEN_VIEW_PROFILE_MODAL, data });
  };
};

export const closeViewProfileModal = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_VIEW_PROFILE_MODAL });
  };
};

export const getFAQ = () => {
  return (dispatch) => {
    const data = getFAQData();

    dispatch({ type: actionTypes.GET_AJOPAY_FAQS, data });
  };
};
