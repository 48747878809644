import React from "react";

import SideDrawer from "./Components/Drawer/sideDrawer";
import TopDrawer from "./Components/Drawer/topDrawer";
import AjoDrawer from "./Components/Drawer/ajoDrawer";
import Sidebar from "./Components/Sidebar";
import SavingsSearch from "./Pages/Search/search_savings";
import ShowAjosDrawer from "./Pages/Services/show_ajo_types_drawer";

const SharedComponents = () => {
  return (
    <div>
      <SideDrawer>
        <Sidebar />
      </SideDrawer>
      <TopDrawer>
        <SavingsSearch />
      </TopDrawer>
      <AjoDrawer>
        <ShowAjosDrawer />
      </AjoDrawer>
    </div>
  );
};

export default SharedComponents;
